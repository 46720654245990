export default class OffsetCardCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initListeners() {
        $.hook('offset-card-carousel').each((i, element) => {
            const $teamOffsetCardSlides = $('.offset-card-slides', element);
            const $prev = $('.slide-prev', element);
            const $next = $('.slide-next', element);

            if ($teamOffsetCardSlides && $teamOffsetCardSlides) {
                $teamOffsetCardSlides.slick({
                    prevArrow: $prev,
                    nextArrow: $next,
                    adaptiveHeight: true
                });
            }
        });
    }
}