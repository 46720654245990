export default class Countdown {
    constructor(App) {
        this.app = App;
        this.$coundown = $.hook('countdown-date');
        this.$daysSpan = this.$coundown.find('.days');
        this.$hoursSpan = this.$coundown.find('.hours');
        this.$minutesSpan = this.$coundown.find('.minutes');
        this.$secondsSpan = this.$coundown.find('.seconds');
        this.timeinterval = null;
        this.deadline = '';
        this.initialTotalTime = {};

        // Bail early
        if (!this.$coundown.length) {
            return;
        }

        this.initListeners();
    }

    initListeners() {
        this.deadline = this.$coundown.data('countdown-date');
        this.initialTotalTime = this.getTimeRemaining();

        this.initCountdown();
    }

    initCountdown() {
        this.updateClock(true);
        this.timeinterval = setInterval(this.updateClock.bind(this), 1000);
    }

    updateClock(initialUpdate) {
        const t = this.getTimeRemaining();

        if (initialUpdate) {
            this.$daysSpan.html(t.days);
            this.$hoursSpan.html(('0' + t.hours).slice(-2));
            this.$minutesSpan.html(('0' + t.minutes).slice(-2));
            this.$secondsSpan.html(('0' + t.seconds).slice(-2));
        }

        this.updateElement(this.$daysSpan, 'days', t);
        this.updateElement(this.$hoursSpan, 'hours', t);
        this.updateElement(this.$minutesSpan, 'minutes', t);
        this.updateElement(this.$secondsSpan, 'seconds', t);

        if (t.total <= 0) {
            clearInterval(this.timeinterval);
        }
    }

    updateElement($el, timeSpan, time) {
        console.log('TIME UPDATE', timeSpan, time[timeSpan], this.initialTotalTime[timeSpan]);

        if (time[timeSpan] != this.initialTotalTime[timeSpan]) {
            if (timeSpan === 'days') {
                $el.html(time.days);
            } else {
                $el.html(('0' + time[timeSpan]).slice(-2));
            }

            // Update initial total time except seconds
            if (timeSpan !== 'seconds') {
                this.initialTotalTime[timeSpan] = time[timeSpan];
            }
        }
    }

    getTimeRemaining() {
        const total = Date.parse(this.deadline) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    }
}