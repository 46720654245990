export default class TestimonialCarousel  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initCarousels() {
        $.hook('testimonial-carousel').each(function(index, obj){
            const $testimonialModule = obj;
            const $testimonialSlides = $('.testimonial-slides', $testimonialModule)[0];
            const $prev = $('.slick-prev', $testimonialModule);
            const $next = $('.slick-next', $testimonialModule);

            if ($testimonialSlides && $testimonialSlides.id) {
                const $testimonialSlidesInstance = $(`#${$testimonialSlides.id}`);

                // initialize slick
                $testimonialSlidesInstance.slick({
                    prevArrow: $prev,
                    nextArrow: $next,
                });
            }
        });
    }

    initListeners() {
        this.initCarousels();
    }
}