export default class GlobalUpdates {
    constructor(App) {
        this.app = App;
        this.$main = $.hook('main');
        this.$modules = this.$main.children();
        this.grayClass = 'background-athens-gray';
        this.firstGray = 'module-first-gray';
        this.lastGray = 'module-last-gray';

        this.adjustGrayModulesSpacing();
    }

    /**
     * Loop through all modules and determined which one is the first and which
     * one is the last, add special classes so we can position them properly
     */
    adjustGrayModulesSpacing() {
        let $previous = null;
        this.$modules.each((i, el) => {
            const $current = $(el);
            const $next = $current.next();
            const $prev = $current.prev();

            // If this is the first element let's add a special class to mark it as such
            if ($current.hasClass(this.grayClass)) {
                // It's first if the previous element doesn't have the background class
                if ($prev.length && !$prev.hasClass(this.grayClass)) {
                    $current.addClass(this.firstGray);
                }

                // If the next element doesn't have the background class then this must be the last in the list
                if ($next.length && !$next.hasClass(this.grayClass)) {
                    $current.addClass(this.lastGray);
                }
            }
        });
    }
}