import Grnhse from './embed';
import AlgoliaClient  from '@core/algolia-client';

export default class Greenhouse {
    constructor(App) {
        this.app = App;
        this.gh = Grnhse;

        var indexName = window.WestMonroe.GlobalConfig.GreenhouseAlgoliaIndex || 'dev_wm_jobs';

        this.searchIndex = AlgoliaClient.getIndex(indexName);

        this.$jobInfo = $.hook('job-info');
        this.redirectOnSubmitUrl = this.$jobInfo.data('redirect-url');
        this.jobInfoTemplate = $.hook('job-info-template').remove().html();

        // Wait 3 seconds before redirect
        this.redirectTimeout = 3000;

        // Bail early if not on a jobs page
        if (!this.$jobInfo.length) {
            return;
        }

        // Load iframe job
        this.gh.Iframe.load();

        this.events = this.getEvents();

        this.getJob();
        this.iframeListener();
    }

    getJob() {
        const jobId = this.gh.UriHelper.getParam(this.gh.Const.JOB_ID);
        this.searchIndex.getObject(jobId).then(object => {
            this.$jobInfo.html(
                AlgoliaClient.render({jobInfo: this.jobInfoTemplate}, 'jobInfo', object)
            );
        });
    }

    iframeListener() {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

        // Listen for a message from the iframe.
        eventer(messageEvent, e => {
            if (typeof e.data !== 'undefined' && typeof this.events[e.data] !== 'undefined') {
                this.events[e.data](e);
            }
        }, false);
    }

    getEvents() {
        return {
            'greenhouse.confirmation': this.onConfirmation.bind(this)
        };
    }

    onConfirmation(e) {
        // Redirect user once they submit the application
        if (this.redirectOnSubmitUrl !== '') {
            setTimeout(() => {
                window.location.href = this.redirectOnSubmitUrl;
            }, this.redirectTimeout);
        }
    }
}