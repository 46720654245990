function buildString(hookName) {
    let selector = '[data-js-hook';
    selector += (!hookName || hookName === '*')?  ']' : '~="' + hookName + '"]';
    return selector;
}

export function hook(hookName) {
    const selector = buildString(hookName);
    return $(selector);
}

export function hookStr(hookName) {
    const selector = buildString(hookName);
    return selector;
}