import { DefaultSearch }  from '@modules/search';

export default class OfferingsSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $searchResultsTemplate  = $.hook('offerings-results-item-template').remove();

        this.$offeringsSearchBar      = $.hook('offerings-search-bar');
        this.$offeringsSearchResults  = $.hook('offerings-search-results');

        this.searchResultsTemplate    = $searchResultsTemplate.html();
        this.searchBoxInputStr        = $.hookStr('search-box-input');
        this.searchResultItemsStr     = $.hookStr('search-results-items');

        this.attributesToFilterBy     = ['industries.lvl0', 'industries.lvl1', 'services.lvl0', 'services.lvl1'];

        // Bail out early if offerings search does not exist
        if (!this.$offeringsSearchBar.length || !this.$offeringsSearchResults.length) {
            return;
        }

        this.setupFilters();

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        // Limit search to offerings
        this.searchTemplate = 'Offering';

        // Custom configuration for offerings search
        this.config = {
            hitsPerPage: 24,
            filters: `templateName:${this.searchTemplate}`,
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Setup the config for each widget
        this.widgets = [
            {
                searchBox: {
                    container: this.$offeringsSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    queryHook: this.onQueryHook.bind(this)
                }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                currentRefinements: {
                    container: this.$selectedFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderCurrentRefinements.bind(this)
                }
            }, {
                clearRefinements: {
                    container: this.$clearFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderClearRefinements.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$offeringsSearchResults[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.teamMemberTemplate
                    }
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        this.init();
    }
}
