import { DefaultSearch } from '@modules/search';

export default class JobsSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        this.$jobSearchBar              = $.hook('jobs-search-bar');
        this.$jobsBoard                 = $.hook('jobs-board').remove();
        this.$jobsSearchResults         = $.hook('jobs-search-results');
        const $searchResultsTemplate    = $.hook('jobs-results-item-template').remove();
        this.searchResultsTemplate      = $searchResultsTemplate.html();

        this.attributesToFilterBy       = ['department', 'careerSitePersonas', 'hireType', 'jobType', 'location'];

        // Bail out early if perspectives search does not exist
        if (!this.$jobsSearchResults.length) {
            return;
        }

        this.setupFilters();
        this.INDEX_NAME = window.WestMonroe.GlobalConfig.GreenhouseAlgoliaIndex || 'dev_wm_jobs';

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        this.config = {
            filters: this.$jobsBoard.data('board') ? this.$jobsBoard.data('board') : 'westmonroe1',
            hitsPerPage: 10,
        };

        this.widgets = [
            {
                searchBox: {
                    container: this.$jobSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    queryHook: this.onQueryHook.bind(this)
                }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                currentRefinements: {
                    container: this.$selectedFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderCurrentRefinements.bind(this)
                }
            }, {
                clearRefinements: {
                    container: this.$clearFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderClearRefinements.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$jobsSearchResults[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.searchResultsTemplate
                    },
                    transformItems: (hits) => hits.map(hit => {
                        // Build job url
                        const { pathname } = window.location;
                        const srcParam = this.getUrlParam('gh_src');
                        const basePath = pathname.substr(-1) === '/' ? pathname : pathname + '/';
                        let jobUrl = '';

                        jobUrl = `${basePath}apply?gh_jid=${hit.jobId}`;

                        // Add Greenhouse source param
                        if (srcParam) {
                            jobUrl += `&gh_src=${srcParam}`;
                        }

                        hit.jobUrl = jobUrl;

                        // Add location to title to `westmonroe1` job types
                        hit.title = `${hit.title} ${hit.boardType === 'westmonroe1' ? `(${hit.location})` : ''}`;

                        return hit;
                    })
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        // Initialize the search
        this.init();

        this.wrapFilters();
    }

    wrapFilters() {
        const $filters = this.$jobSearchBar.find('.search-filters');
        const filtersWidth = $filters.outerWidth();
        let filtersChildrendWidth = 0;
        $filters.children().filter(':visible').map((i, el) => filtersChildrendWidth += $(el).outerWidth(true));

        if (filtersChildrendWidth > filtersWidth) {
            this.$jobSearchBar.find('.search-bar').addClass('search-bar--wrap');
        }
    }

    /**
     * Return an individual param from a query string. e.g. ?post=1234&action=edit&active=1
     *
     * @param  {string} name
     * @return {string}
     */
    getUrlParam(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}