import VideoPlayer from '@core/video-player';

export default class VideoUtility {
    constructor(videoWrapper, videoPlayerStr, App, instance) {
        this.players = {};
        this.app = App;
        this.$instance = instance;
        this.videoPlayerStr = videoPlayerStr;
        this.$videoWrapper = videoWrapper;
        // this.videoPlayerStr = $.hookStr('video-player');
        // this.$videoWrapper = $.hook('video-wrapper');

        // Bail early if element don't exist
        if (this.$videoWrapper.length === 0) {
            return;
        }

        this.setupVideoPlayers();
    }

    setupVideoPlayers() {
        this.$videoWrapper.each((i, el) => {

            const $videoWrapper = $(el);
            const $videoPlayer = $videoWrapper.find(this.videoPlayerStr);
            const player = new VideoPlayer({
                app: this.app,
                $videoPlayer,
                onPlayerReady: this.onPlayerReady.bind(this, $videoWrapper, $videoPlayer, this.$instance)
            });

            // Save the player and assign id to wrapper
            this.players[player.id] = player;
            $videoWrapper.attr('data-player-id', player.id);
        });
    }

    onPlayerReady($videoWrapper, $videoPlayer, $instance) {
        const playerId = $videoPlayer.attr('id') || null;
        $videoWrapper.on('click', (e) => {
            const $el = $(e.currentTarget);
            
            if($instance.length) {
                $instance.slick('slickPause');
            }

            this.pauseAllPlayers(this.players);
            $el.addClass('loading');

            //play video
            if (playerId) {
                this.players[playerId].play();
            }
        });

        this.app.off(VideoPlayer, 'PLAYING');
        this.app.on(VideoPlayer, 'PLAYING', (event, player) => {
            const $videoWrapper = this.$videoWrapper.filter(`[data-player-id="${player.id}"]`);

            // Add the playvideo class to the video wrapper to hide image, button and caption
            if ($videoWrapper.length && !$videoWrapper.hasClass('playVideo')) {
                $videoWrapper
                    .addClass('playVideo')
                    .removeClass('loading');
            }
        });
    }

    /**
     * Pause all video players
     */
    pauseAllPlayers(players) {
        $.each(players, (id, playerObj) => {
            const { player } = playerObj;
            // Play only if the player has been setup
            if (player) {
                playerObj.pause();
            }
        });
    }
}