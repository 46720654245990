/**
 * Custom widget that allows to have nested disjunctive facets for more info see:
 * https://www.algolia.com/doc/guides/building-search-ui/widgets/create-your-own-widgets/js/
 * @param  {Object} widgetParams
 * @return {Object}
 */
export default function nestedRefinements(widgetParams) {
    let triggerRefine;
    const { attributes, container, isSearchLanding } = widgetParams;
    const separator = ('separator' in widgetParams) ? widgetParams.separator : ' > ';
    /**
     * @devNote: Currently working with only 1 level deep so
     * we are grabbing those values manually and assigning
     * them so variables
     * @type {String}
     */
    const lvl0 = attributes[0];
    const lvl1 = attributes[1];

    // Define render function
    const _renderFunc = renderOptions => {
        const { createURL, items, refine, state } = renderOptions;

        // Compute a specific createURL method able to link to any facet value state change
        const _createURL = facetValue => {
            // If the separator exists in the facetValue it must be a lvl1
            const attr = facetValue.indexOf(separator) > -1 ? lvl1 : lvl0;

            return createURL(state.toggleRefinement(attr, facetValue));
        };

        const renderFunc = ('renderFunc' in widgetParams) ? widgetParams.renderFunc : nestedRefinements._renderFunc;

        renderFunc({
            createURL: _createURL,
            items,
            refine,
            state
        });
    };

    const nestedRefinements = {
        init(opts) {
            const { createURL, helper, state, templatesConfig } = opts;

            // Define the refine trigger
            triggerRefine = (facetValue) => {
                // If the separator exists in the facetValue it must be a lvl1
                const attr = facetValue.indexOf(separator) > -1 ? lvl1 : lvl0;

                return helper.toggleRefinement(attr, facetValue).search();
            };

            /**
             * @devNote: The following code could be abstracted by passing a render
             * function and handled outside of the widget. For time sake, it's
             * being done here instead
             */
            // Do initial render
            _renderFunc({
                items: [],
                createURL,
                state: helper.state,
                refine: triggerRefine
            });
        },

        render(renderOptions) {
            const { createURL, results, helper, state } = renderOptions;
            const facetValues0 = results.getFacetValues(lvl0, {sortBy: ['name:asc']});
            const facetValues1 = results.getFacetValues(lvl1, {sortBy: ['name:asc']});
            const facetValues = this._mergeFacetValues(facetValues0, facetValues1);

            _renderFunc({
                state,
                createURL,
                items: facetValues,
                refine: triggerRefine,
            });
        },

        dispose({ state }) {
            const withoutMaxValuesPerFacet = state.setQueryParameter('maxValuesPerFacet', undefined);

            return withoutMaxValuesPerFacet
                .removeDisjunctiveFacet(lvl0)
                .removeDisjunctiveFacet(lvl1);
        },

        getWidgetState(uiState, widgetStateOptions) {
            const { searchParameters } = widgetStateOptions;
            const values0 = searchParameters.getDisjunctiveRefinements(lvl0);
            const values1 = searchParameters.getDisjunctiveRefinements(lvl1);
            const values = values0.concat(values1);

            if (!values.length) {
                return uiState;
            }

            return Object.assign({}, uiState, {
                refinementList: Object.assign({}, uiState.refinementList, {
                    [lvl0]: values0,
                    [lvl1]: values1,
                })
            });
        },

        getWidgetSearchParameters(searchParameters, widgetSearchParametersOptions) {
            const { uiState } = widgetSearchParametersOptions;
            const values0 = uiState.refinementList && uiState.refinementList[lvl0];
            const values1 = uiState.refinementList && uiState.refinementList[lvl1];
            const values = [].concat(
                values0 ? values0 : [],
                values1 ? values1 : []
            );

            // Get refinements and clear our `lvl0, lvl1` attributes
            const withoutRefinements = searchParameters
                .clearRefinements(lvl0)
                .clearRefinements(lvl1)
                // Add the attribute faces. Disjunctive means and "or" filter
                .addDisjunctiveFacet(lvl0)
                .addDisjunctiveFacet(lvl1);

            if (!values.length) {
                return withoutRefinements.setQueryParameters({
                    disjunctiveFacetsRefinements: Object.assign({}, withoutRefinements.disjunctiveFacetsRefinements, {
                        [lvl0]: [],
                        [lvl1]: [],
                    }),
                });
            }

            return values.reduce((parameters, value) => {
                const attr = value.indexOf(separator) > -1 ? lvl1 : lvl0;
                return parameters
                    .addDisjunctiveFacetRefinement(attr, value);
            }, withoutRefinements);
        },

        /**
         * @private
         * Merge the values from facets in lvl1 into it's parent in lvl0
         * @param  {Array} level0
         * @param  {Array} level1
         * @return {Array}
         */
        _mergeFacetValues(level0, level1) {
            // Merged facets
            level1.forEach(child => {
                const values = child.name.split(separator);
                const parentLabel = values[0];
                const childLabel = values[1];
                let parent = level0.find(val => val.name === parentLabel);

                // Skip this child if a parent doesn't exist
                if (typeof(parent) === 'undefined') {
                    return true;
                }

                // Push children to parent
                parent.data = parent.data || [];
                parent.data.push(child);
            });

            // Return normalized values
            return level0.map(parent => {
                return this._normalizeFacetValue(parent);
            });
        },

        /**
         * @private
         * Normalize the data for the facet values
         * @param  {Object} facet
         * @return {Object}
         */
        _normalizeFacetValue(facet) {
            const { name, count, isRefined, data } = facet;
            const values = name.split(separator);
            const label = values.length > 1 ? values[1] : values[0];
            let returnObj = {};
            let normalizedData;

            if (typeof(data) !== 'undefined') {
                normalizedData = data.map(item => this._normalizeFacetValue(item));
                returnObj.data = normalizedData;
            }

            // Assign all the facet data that needs to be returned
            return Object.assign({}, returnObj, {
                count,
                label,
                isRefined,
                value: name
            });
        },

        _renderFunc({items, createURL, state, refine}, isFirstRender) {
            // Removes the "." on the attributes so they work with the search routing
            const attributesForRouting = widgetParams.attributes.map(attr => attr.replace('.', ''));

            if (items.length) {
                const renderCheckbox = (item, level) => `
                    <li class="eyebrow search-filter-item">
                        <label for="${item.value}"><input type="checkbox" ${item.isRefined ? 'checked' : ''} value="${item.value}" id="${item.value}" name="${attributesForRouting[level]}"> ${item.label}
                        </label>
                        ${item.data ? `
                            <ul class="search-filter-options-sublist">
                                ${item.data.map(isSearchLanding ? subItem => renderCheckbox(subItem, 1): renderLink).join('')}
                            </ul>
                        ` : ''}
                    </li>
                `;

                const renderLink = item => `
                    <li class="eyebrow search-filter-item">
                        <a href="${createURL(item.value)}" class="${item.isRefined ? 'selected' : ''}" data-value="${item.value}">${item.label}</a>
                            ${item.data ? `
                                <ul class="search-filter-options-sublist">
                                    ${item.data.map(isSearchLanding ? renderCheckbox : renderLink).join('')}
                                </ul>
                            ` : ''}
                    </li>
                `;

                widgetParams.container.innerHTML = `
                    <ul class="search-filter-options-list">
                        ${items.map(isSearchLanding ? subItem => renderCheckbox(subItem, 0) : renderLink).join('')}
                    </ul>
                `;

                $(widgetParams.container).find('a').off('click').on('click', (event) => {
                    event.preventDefault();

                    // Refine only if we're in a search results page
                    if (!isSearchLanding) {
                        refine(event.currentTarget.dataset.value);
                    }
                });
            }
        }
    };

    return nestedRefinements;
}