//deprecated. video initialization now happens in video-utility.js
import VideoPlayer from '@core/video-player';
import VideoUtility from '@core/video-player/video-utility';

export default class Video {
    constructor(App) {
        this.app = App;
        this.$singleVideo = $.hook('single-video');
        this.videoPlayerStr = $.hookStr('video-player');
        this.$videoWrapper = $.hook('video-wrapper');

        this.VideoUtility = new VideoUtility(this.$videoWrapper, this.videoPlayerStr, App, '');
    }
}