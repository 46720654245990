export default class FourupCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    fourupSlides() {        
        $.hook('fourup-carousel').each(function(index, obj){
            const $fourupModule = obj;
            const $fourupSlides = $('.fourup-slides', $fourupModule)[0];
            const $prev = $('.slide-prev', $fourupModule);
            const $next = $('.slide-next', $fourupModule);
        
            if ($fourupSlides && $fourupSlides.id) {
                const $fourupSlidesInstance = $(`#${$fourupSlides.id}`);

                //reset the timer when the slide changes
                $fourupSlidesInstance.on('beforeChange', function(){
                    $('.slider-countdown', $fourupModule).removeClass('countingDown');
                });

                //restart the timer when the slide changes
                $fourupSlidesInstance.on('afterChange', function(){
                    $('.slider-countdown', $fourupModule).addClass('countingDown');
                });

                //initialize slick
                $fourupSlidesInstance.slick({
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rows: 2,
                    slidesPerRow: 1,
                    prevArrow: $prev,
                    nextArrow: $next,
                    pauseOnHover: false,
                    centerMode: false,
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                autoplay: true
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                autoplay: true,
                                slidesToShow: 1,
                                rows: 1,
                                slidesPerRow: 1
                            }
                        }
                    ]                    

                });
            }
        });
    }


    initListeners() {
        this.fourupSlides();
    }
}