export default class FeatureBlocks {
    constructor(App) {
        this.app = App;

        this.$nav = $.hook('feature-blocks');
        this.$mainContainer = $.hook('main');
        this.$titleBar = $.hook('title-bar');
        this.fixedClass = 'feature-blocks-fixed';
        this.openClass = 'feature-blocks-open';
        this.titleBarHeight = 0;
        this.isMobile = false;

        // Bail early if nav element doesnt exist
        if (!this.$nav.length) {
            return;
        }

        if (this.$nav.length) {
            //prevents error on pages without persistent nav. maybe a better solution exists.
            this.originalOffset = this.$nav.offset().top;
        }

        this.isNavFixed = false;
        this.initListeners();

    }

    initListeners() {
        const { modules } = this.app;

        this.app.on(modules.Header.constructor, 'HEADER_FIXED', this.onHeaderFixed.bind(this));

        // Ensure nav is in the right position on resize
        this.app.$win.on('resize', $.debounce(this.onResize.bind(this), 250));

        // Make nav sticky on scroll
        this.app.$win.on('load scroll', this.onScroll.bind(this));


        // keep track of width
        this.isMobile = (this.app.$win.width() < 861);

    }

    onHeaderFixed() {
        if (!this.titleBarHeight) {
            this.titleBarHeight = this.$titleBar.outerHeight();
        }
    }

    onResize() {
        // Update header height
        this.titleBarHeight = this.$titleBar.outerHeight();

        if (this.app.modules.Header.isHeaderFixed) {
            this.lockNav();
        }
    }

    /**
     * Lock the navigation once it reaches the bottom of the header
     */
    onScroll() {

        // close it
        this.$nav.removeClass(this.openClass);

        const top = this.titleBarHeight + this.app.$win.scrollTop();

        if (top >= this.originalOffset) {
            if (!this.isNavFixed) {
                this.lockNav();
                this.addPadding();
            }
            this.isNavFixed = true;
        } else {
            if (this.isNavFixed) {
                this.unlockNav();
                this.removePadding();
            }
            this.isNavFixed = false;
        }        
    }

    lockNav() {
        this.$nav.addClass(this.fixedClass);
        this.$nav.css('top', this.titleBarHeight);

    }

    unlockNav() {
        this.$nav.removeClass(this.fixedClass);
        this.$nav.removeAttr('style');

    }

    addPadding() {
        // always 50px on mobile
        const navHeight = this.isMobile ? 50 : this.$nav.height();

        // Check if the main container has any padding applied
        let paddingTop = Math.round(parseInt(this.$mainContainer.css('padding-top')));

        // Save this value for when unlock if it has any
        if (paddingTop) {
            this.$mainContainer.data('padding-top', paddingTop);
        }
        // Now combine paddings
        paddingTop = paddingTop + navHeight;

        // Add padding to main container
        this.$mainContainer.css('padding-top', paddingTop);
    }

    removePadding() {
        // Check if the main container has any padding saved
        const paddingTop = this.$mainContainer.data('padding-top');

        if (paddingTop) {
            this.$mainContainer.css('padding-top', paddingTop);
        } else {
            this.$mainContainer.removeAttr('style');
        }
    }

    toggleShareOptions(e) {
        const $el = $(e.currentTarget);

        this.$toggleShare.parent().toggleClass('openShare');

    }
}
