export default class ImageCardCarousel  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initCarousels() {
        $.hook('image-card-carousel').each(function(index, obj){
            const $ImageCardModule = obj;
            const $ImageCardSlides = $('.image-card-slides', $ImageCardModule)[0];
            const $prev = $('.slide-prev', $ImageCardModule);
            const $next = $('.slide-next', $ImageCardModule);

            if ($ImageCardSlides && $ImageCardSlides.id) {
                const $ImageCardSlidesInstance = $(`#${$ImageCardSlides.id}`);

                // reset the timer when the slide changes
                $ImageCardSlidesInstance.on('beforeChange', function(){
                    $('.slider-countdown', $ImageCardModule).removeClass('countingDown');
                });

                // restart the timer when the slide changes
                $ImageCardSlidesInstance.on('afterChange', function(){
                    $('.slider-countdown', $ImageCardModule).addClass('countingDown');
                });

                //after init, check to see if arrows are hidden. if not hidden, show countdown
                $ImageCardSlidesInstance.on('init', function(){
                    if(!$prev.hasClass('slick-hidden')) {
                        $('.slider-countdown', $ImageCardModule).removeClass('slick-hidden');
                    }
                });

                //determine layout
                var layoutSize = $('.image-card-slides', $ImageCardModule).attr('data-column-layout');
                var slidesToShow = parseInt(layoutSize.replace(' Column', ''));
                var slidesToShowTablet = 2;


                if(slidesToShow == 1) {
                    slidesToShowTablet = 1;
                }
                // initialize slick
                $ImageCardSlidesInstance.slick({
                    centerMode: false,
                    variableWidth: false,
                    centerPadding: 0,
                    prevArrow: $prev,
                    nextArrow: $next,
                    autoplay: true,
                    autoplaySpeed: 4800,
                    slidesToShow: slidesToShow,
                    slidesToScroll: slidesToShow,
                    pauseOnHover: false,
                    responsive: [
                        {
                            breakpoint: 1023,
                            settings: {
                                slidesToShow: slidesToShowTablet,
                                slidesToScroll: slidesToShowTablet,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            }
        });
    }

    initListeners() {
        this.initCarousels();
    }
}