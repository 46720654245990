export default class Security {
    constructor(App) {
        this.app = App;

        // This is super rudimentary.  There are better ways to do this but ultimately you can't prevent this, even with obfuscation
        // so it's a diminishing returns problem.  Basically, we are trying to make sure there aren't any easy to find strings.  
        // This will not stop a determined attacker at all.

        // These are the "hashCode()" values for the following hostnames
        // westmonroe.local -393779736
        // staging.westmonroe.com 481511633
        // westmonroe.com 1166091966
        // www.westmonroe.com 612544501
        // admin.westmonroe.com 977621821
        // westmonroepartners.com 1863203561
        // www.westmonroepartners.com 925539616
        // wm-dev.happycogdev.com 1328912349

        // and respectively the "btoa()" values
        // westmonroe.local 'LTM2Mzc3OTczNg==x'
        // staging.westmonroe.com 'NDgxNTExNjMz'
        // westmonroe.com 'MTE2NjA5MTk2Ng=='
        // www.westmonroe.com 'NjEyNTQ0NTAx'
        // admin.westmonroe.com 'OTc3NjIxODIx'
        // westmonroepartners.com 'MTg2MzIwMzU2MQ=='
        // www.westmonroepartners.com 'OTI1NTM5NjE2'
        // localhost 'LTEyMDQ2MDcwODU='
        // wm-dev.happycogdev.com 'MTMyODkxMjM0OQ=='

        var validHashes = ['LTM2Mzc3OTczNg==', 'NDgxNTExNjMz', 'MTE2NjA5MTk2Ng==', 'NjEyNTQ0NTAx', 'OTc3NjIxODIx', 'MTg2MzIwMzU2MQ==', 'OTI1NTM5NjE2', 'LTEyMDQ2MDcwODU=', 'MTMyODkxMjM0OQ=='];

        // when we redirect we want to make it hard to find where it is happening, so we "encode" the destination url
        var hiddenUrl = 'L21vYyRlb3Jub210c2V3QHd3dy8vOnNwdHRo';

        if (!validHashes.includes(btoa(this.hashCode(window.location.hostname)))) {
            window.location.replace(this.decodeUrl(hiddenUrl));
        }
    }

    decodeUrl(str) {
        return atob(str).replace('$', '.').replace('@', '.').split('').reverse().join('');
    }
    
    hashCode(str) {
        let hash = 0;
        for (let i = 0, len = str.length; i < len; i++) {
            let chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}
