/**
 * Get Foundation's core and global plugins
 */
import { Foundation } from 'foundation-sites/js/foundation.core';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
import { Drilldown} from 'foundation-sites/js/foundation.drilldown';
import { Magellan } from 'foundation-sites/js/foundation.magellan';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
const globalFoundationPlugins = [Drilldown, Magellan, OffCanvas, Reveal, SmoothScroll];

/**
 * Get global modules
 */
import Header from '@modules/header';
import Security from '@modules/security';
const globalModules = [Header, Security];

/**
 * Get global libraries e.g. select2, is_js, lodash, etc
 */
import SlickCarousel from 'slick-carousel';
import 'datatables.net';
import * as inobounce from 'inobounce';
// Inobounce is enabled by default so we'd want to disable it as soon as we can
if (inobounce.isEnabled()) {
    inobounce.disable();
}

/**
 * Global includes
 * These are share modules like jquery extensions, polyfills, etc
 * What ever is define here should be assigned to the window variable
 */
import '@core/jquery-extensions';
import '@core/polyfills';
import '@core/video-player/video-utility';

/**
 * Main App initializer
 */
export default class App {
    constructor(opts) {
        // Set class options
        this.opts = opts || {};

        // Set breakpoints that match SCSS settings: ./fe-src/scss/global/_settings.scss
        this.breakpoint = {
            small:     0,
            medium:    768,
            large:     1024,
            xlarge:    1200,
            xxlarge:   1440,
        };

        this.$win = $(window);

        // Define modules object
        this.modules = {};

        // Set Global modules
        this.globalModules = globalModules;
        this.globalFoundationPlugins = globalFoundationPlugins;
        this.initListeners();
    }

    /**
     * @desc Initialize event listeners
     * @return {void}
     */
    initListeners() {
        // Fires when the document is ready
        $.ready.then(this.onDocReady.bind(this));

        // Fires when the window is load
        this.$win.on('load', this.docLoaded.bind(this));
    }

    /**
     * @desc Fires when the document is ready and initializes global
     *       Foundation plugins and global modules
     * @return {void}
     */
    onDocReady() {
        this.initGlobalFoundationPlugins();
        this.initGlobalModules();

        const { modules, foundationPlugins } = this.opts;

        // Init foundation plugins
        if (Object.keys(foundationPlugins).length > 0) {
            $.each(foundationPlugins, this.bindFoundationPlugin.bind(this));
        }

        if (this.globalFoundationPlugins.length || Object.keys(foundationPlugins).length > 0) {
            // Allow foundation plugins to be initialized via data attr
            $(document).foundation();
        }

        // Init modules
        if (modules) {
            $.each(modules, this.initModule.bind(this));
        }

        // Dispatch ready event
        this.dispatch(App, 'APP_DOC_READY');
    }

    docLoaded() {
        $('html').removeClass('loading');

        // Dispatch loaded event
        this.dispatch(App, 'APP_DOC_LOADED');
    }

    /**
     * @desc Initializes all global foundation plugins
     * @return {void}
     */
    initGlobalFoundationPlugins() {
        this.opts.foundationPlugins = this.opts.foundationPlugins || {};
        // Add Foundation to the jQuery object
        Foundation.addToJquery($);

        this.globalFoundationPlugins.forEach(plugin => {
            const pluginName = plugin.name || plugin.className;
            if (!Object.prototype.hasOwnProperty.call(this.opts.foundationPlugins, pluginName)) {
                this.bindFoundationPlugin(pluginName, plugin);
            }
        });
    }

    /**
     * @desc Binds foundation plugin to the main Foundation object
     * @param  {String} name
     * @param  {Object} plugin
     * @return {void}
     */
    bindFoundationPlugin(name, plugin) {
        Foundation.plugin(plugin, name);
    }

    /**
     * @desc Set all modules in the globaleModules array so
     *       they can be initialize in the modules
     *       initialization process
     * @return {void}
     */
    initGlobalModules() {
        this.globalModules.forEach(module => {
            const moduleName = module.name;
            if (!Object.prototype.hasOwnProperty.call(this.modules, moduleName)) {
                this.modules[moduleName] = new module(this);
            }
        });
    }

    /**
     * @desc Initializes and instance of the given module and saves it
     *       into an array in the main class
     * @param  {String} name
     * @param  {Class} module
     * @return {void}
     */
    initModule(name, module) {
        // Passing the app instance to the modules allowing
        // modules to access other modules and share content
        this.modules[name] = new module(this);
    }

    /**
     * @desc   Handle events fired with the dispatcher,
     *         events are bound to the window object
     * @param  {Class}   cls        Class to use as namespace
     * @param  {String}   event     Event being fired
     * @param  {Function} cb        Callback when the event fires
     */
    on(cls, event, cb) {
        // Get the class namespace or set a default to App
        const className = cls.name || 'App';
        this.$win.on(`${event}.${className}`, cb);
    }

    /**
     * @desc   Removes events fired with the dispatcher
     * @param  {Class}   cls        Class to use as namespace
     * @param  {String}   event     Event being fired
     */
    off(cls, event) {
        // Get the class namespace or set a default to App
        const className = cls.name || 'App';
        this.$win.off(`${event}.${className}`);
    }

    /**
     * @desc   Fire events from other modules
     *         events are bound to the window object
     * @param  {Class} cls       Class to use as namespace
     * @param  {String} event    Event being fired
     * @param  {Mixed} data      Data to be passed to the callback
     */
    dispatch(cls, event, data) {
        // Get the class namespace or set a default to App
        const className = cls.name || 'App';
        this.$win.trigger(`${event}.${className}`, data);
    }

    lock() {
        inobounce.enable();
    }

    unlock() {
        inobounce.disable();
    }
}
