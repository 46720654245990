import { DefaultSearch }  from '@modules/search';

export default class GlobalSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $globalSearchTemplate    = $.hook('global-results-item-template').remove();

        this.$globalSearchBar          = $.hook('global-search-bar');
        this.$globalSearchResuls       = $.hook('global-search-results');
        this.$globalSearchSort         = $.hook('search-results-sort');

        this.globalSearchTemplate      = $globalSearchTemplate.html();

        // Bail out early if perpectives search does not exist
        if (!this.$globalSearchBar.length || !this.$globalSearchResuls.length) {
            return;
        }

        // JS templates
        this.templates = {
            hits: {
                item: this.globalSearchTemplate
            }
        };

        // Custom configuration for global search
        this.config = {
            hitsPerPage: 12,
            restrictSearchableAttributes: [
                'title',
                'fullName',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Setup the config for each widget
        this.widgets = [
            {
                searchBox: {
                    container: this.$globalSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    queryHook: this.onQueryHook.bind(this)
                }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                sortBy: {
                    container: this.$globalSearchSort[0],
                    renderFunc: this.renderSortBy.bind(this),
                    items: [
                        { label: 'Most Relevant', value: this.INDEX_NAME },
                        { label: 'Date', value: `${this.INDEX_NAME}_date_desc` },
                    ],
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$globalSearchResuls[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.globalSearchTemplate
                    }
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        // Initialize the search
        this.init();
    }

    renderStats({ hitsPerPage, nbHits, page }) {
        let results = hitsPerPage < nbHits ? hitsPerPage : nbHits;

        // Calculate what results we are displaying
        if (page > 0) {
            results = hitsPerPage * (page + 1);
            results = results < nbHits ? results : nbHits;
        }

        this.$stats.html(`<span class="search-count">Showing ${results} of ${nbHits} results</span>`);
    }

    renderSortBy(renderOptions, isFirstRender) {
        const { options, currentRefinement, refine, widgetParams } = renderOptions;

        if (isFirstRender) {
            $(widgetParams.container).off('click').on('click', 'a', event => {
                event.preventDefault();

                refine(event.currentTarget.dataset.value);
            });
        }

        /* eslint-disable */
        widgetParams.container.innerHTML = `
            <span>Sort By: ${options.map(option => `
                ${option.value === currentRefinement ? '<span>' : `<a href="#" class="underlined-link" data-value="${option.value}">`}
                ${option.label}
                ${option.value === currentRefinement ? '</span>' : '</a>'}
                `).join(' | ')}
            </span>
        `;
        /* eslint-enable */
    }
}
