export default class ContentCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    contentSlides() {
        $.hook('content-carousel').each(function(index, obj){

            const $contentModule = obj;
            const $contentSlides = $('.content-slides', $contentModule)[0];
            const $prev = $('.slide-circular-prev', $contentModule);
            const $next = $('.slide-circular-next', $contentModule);

            if ($contentSlides && $contentSlides.id) {
                const $contentSlidesInstance = $(`#${$contentSlides.id}`);


                // reset the timer when the slide changes
                $contentSlidesInstance.on('beforeChange', function(event, slick){
                    //remove slide classes
                    slick.$slides.removeClass('slick-prev-slide').removeClass('slick-next-slide');

                    //reset the timer
                    $('.slider-countdown', $contentModule).removeClass('countingDown');
                });

                // restart the timer when the slide changes
                $contentSlidesInstance.on('afterChange init', function(event, slick){
                    //add a previous class
                    for (var i = 0; i < slick.$slides.length; i++)
                    {
                        var $slide = $(slick.$slides[i]);
                        if ($slide.hasClass('slick-current')) {
                            // update DOM siblings
                            $slide.prev().addClass('slick-prev-slide');
                            $slide.next().addClass('slick-next-slide');
                            break;
                        }
                    }
                    //run the timer
                    $('.slider-countdown', $contentModule).addClass('countingDown');
                });

                $contentSlidesInstance.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: $prev,
                    nextArrow: $next,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: false,
                    centerMode: true,
                    variableWidth: true
                    // responsive: [
                    //     {
                    //         breakpoint: 1440,
                    //         settings: {
                    //             variableWidth: true
                    //         }
                    //     }
                    // ]

                });
            }
        });
    }

    initListeners() {
        this.contentSlides();
    }
}


// $('.center')
// .on('afterChange init', function(event, slick, direction){
//     console.log('afterChange/init', event, slick, slick.$slides);
//     // remove all prev/next
//     slick.$slides.removeClass('prevdiv').removeClass('nextdiv');

//     // find current slide
//     for (var i = 0; i < slick.$slides.length; i++)
//     {
//         var $slide = $(slick.$slides[i]);
//         if ($slide.hasClass('slick-current')) {
//             // update DOM siblings
//             $slide.prev().addClass('prevdiv');
//             $slide.next().addClass('nextdiv');
//             break;
//         }
//     }
//   }
// )
// .on('beforeChange', function(event, slick) {
//     // optional, but cleaner maybe
//     // remove all prev/next
//     slick.$slides.removeClass('prevdiv').removeClass('nextdiv');
// })
// .slick({
//   centerMode: true,
//   centerPadding: '27%',
//   focusOnSelect: true,
//   slidesToShow: 1,
//   arrows: false,
// });