export default class LargeServices {
    constructor(App) {
        this.app = App;
        this.touchInProgress = false;
        this.initListeners();
    }

    toggleService(e) {
        const $el = $(e.currentTarget);
        var service = $el.attr('data-service');

        const $serviceItem = $('.service-image[data-service="'+service+'"]');
        const $serviceInfo = $('.service-info[data-service="'+service+'"]');


        if ($(window).width() >= 1024) {
            //change the service displayed
            $el.addClass('activeService').siblings().removeClass('activeService');
            $serviceItem.addClass('activeService').siblings().removeClass('activeService');
            $serviceInfo.addClass('activeService').siblings().removeClass('activeService');
        } else {
            if ($el.hasClass('activeService')) {
                //if mobile service is already active, deactivate it
                $el.removeClass('activeService');
                $serviceItem.removeClass('activeService');
                $serviceInfo.removeClass('activeService');
            } else {
                //if nothing is active, create an active state
                $el.addClass('activeService').siblings().removeClass('activeService');
                $serviceItem.addClass('activeService').siblings().removeClass('activeService');
                $serviceInfo.addClass('activeService').siblings().removeClass('activeService');
            }
        }
    }

    desktopClickResponder(e) {
        const $el = $(e.currentTarget);
        const url = $el.data('clickUrl');
        const target = $el.data('clickTarget') || '_self';

        if (e.type === 'touchstart') {
            this.touchInProgress = true;

            setTimeout(() => {
                this.touchInProgress = false;
            }, 500);

            return;
        }

        if (this.touchInProgress ||
            ! $el.hasClass('activeService') ||
            ! $el.hasClass('activeService') ||
            ! url
        ) {
            this.toggleService(e);

            return;
        }

        window.open(url, target);
    }

    initListeners() {
        var $el = $.hook('large-services-item');

        if($(window).width() > 1024) {
            $el.on('mouseenter', this.toggleService.bind(this));
            $el.on('click touchstart', this.desktopClickResponder.bind(this));
        } else {
            $el.on('click', this.toggleService.bind(this));
        }
    }
}
