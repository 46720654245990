import instantsearch from 'instantsearch.js';
import history from 'instantsearch.js/es/lib/routers/history';

// These are refinements that are used in the menu widget
const conjunctiveRefinements = ['lastName', 'role', 'type'];

// Valid refinements, these are the query string keys
export const validRefinements = conjunctiveRefinements.concat([
    'dateMonth',
    'dateYear',
    'formatType',
    'industrieslvl0',
    'industrieslvl1',
    'industryPractices',
    'office',
    'serviceslvl0',
    'serviceslvl1',
    'servicePractices',
    'topicsCorporate',
    'topicsEmployer',
    'location',
    'hireType',
    'function',
    'businessUnit',
    'employmentType',
]);

// Maps query string keys to algolia attributes
const refinementsMap = {
    industrieslvl0: 'industries.lvl0',
    industrieslvl1: 'industries.lvl1',
    lastName: 'lastNameFirstLetter',
    role: 'roles',
    serviceslvl0: 'services.lvl0',
    serviceslvl1: 'services.lvl1',
    type: 'templateName',
    function: 'careerSitePersonas',
    businessUnit: 'department',
    employmentType: 'jobType'
};

// Encode's a string to be URL friendly
const encodeStr = (str) => {
    return str.split(' ').map(encodeURIComponent).join('+');
};

// Decodes a string from a URL friendly string
const decodeStr = (str) => {
    return str.split('+').map(decodeURIComponent).join(' ');
};

// Router
const router = history({
    windowTitle({ query }) {
        const pageTitle = document.title;
        const queryTitle = query ? `Results for "${query}"` : pageTitle;
        return queryTitle;
    },

    // Builds the url that is used on the widgets
    createURL({ qsModule, routeState, location }) {
        const urlParts = location.href.split('?');
        const baseUrl = `${urlParts[0]}`;
        const queryParameters = {};

        if (routeState.query) {
            queryParameters.query = encodeStr(routeState.query);
        }
        if (routeState.page !== 1) {
            queryParameters.page = routeState.page;
        }

        // Add refinements to query parameters
        validRefinements.forEach((refinement, b) => {
            // Only add to query parameters if it exist in the route state
            if ((refinement in routeState) && routeState[refinement]) {
                if (conjunctiveRefinements.indexOf(refinement) > -1) {
                    queryParameters[refinement] = routeState[refinement];
                } else {
                    queryParameters[refinement] = routeState[refinement].map(encodeStr);
                }
            }
        });

        const queryString = qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            encode: false,
            arrayFormat: 'repeat',
        });

        return baseUrl + queryString;
    },

    // // Parses the url when the user reloads the page
    parseURL({ qsModule, location }) {
        const urlQueries = qsModule.parse(location.search.slice(1));
        const { query = '', page } = urlQueries;
        delete urlQueries.query;
        delete urlQueries.page;

        // Build return query obj
        let returnQueryObj = { query, page };

        // Loop through all url queries so we can map the decoded values and return them to the ui
        for (let prop in urlQueries) {
            const query = urlQueries[prop];

            returnQueryObj = Object.assign({}, returnQueryObj, {
                // `qs` does not return an array when there's a single value.
                [prop]: (conjunctiveRefinements.indexOf(prop) > -1)
                    ? decodeStr(query)
                    : Array.isArray(query)
                        ? query.map(decodeStr)
                        : [query].map(decodeStr)
            });
        }

        return returnQueryObj;
    }
});

// State mapping
const stateMapping = (indexName) => {
    return {
        stateToRoute(uiState) {
            // refer to uiState docs for details: https://www.algolia.com/doc/api-reference/widgets/ui-state/js/
            const indexUiState = uiState[indexName];

            let returnState = {
                // Global queries
                query: indexUiState.query,
                page: indexUiState.page,
            };

            // Add refinementList items
            if (indexUiState.refinementList) {
                returnState = Object.assign({}, returnState, {
                    dateMonth: indexUiState.refinementList.dateMonth,
                    dateYear: indexUiState.refinementList.dateYear,
                    formatType: indexUiState.refinementList.formatType,
                    industrieslvl0: indexUiState.refinementList['industries.lvl0'],
                    industrieslvl1: indexUiState.refinementList['industries.lvl1'],
                    industryPractices: indexUiState.refinementList.industryPractices,
                    office: indexUiState.refinementList.office,
                    serviceslvl0: indexUiState.refinementList['services.lvl0'],
                    serviceslvl1: indexUiState.refinementList['services.lvl1'],
                    servicePractices: indexUiState.refinementList.servicePractices,
                    topicsEmployer: indexUiState.refinementList.topicsEmployer,
                    topicsCorporate: indexUiState.refinementList.topicsCorporate,
                    location: indexUiState.refinementList.location,
                    hireType: indexUiState.refinementList.hireType,
                    function: indexUiState.refinementList.careerSitePersonas,
                    businessUnit: indexUiState.refinementList.department,
                    employmentType: indexUiState.refinementList.jobType
                });
            }

            // Add menu items
            if (indexUiState.menu) {
                returnState = Object.assign({}, returnState, {
                    lastName: indexUiState.menu.lastNameFirstLetter,
                    role: indexUiState.menu.roles,
                    type: indexUiState.menu.templateName,
                });
            }

            return returnState;
        },

        routeToState(routeState) {
            // refer to uiState docs for details: https://www.algolia.com/doc/api-reference/widgets/ui-state/js/
            let menu = {};
            let refinementList = {};
            let returnRouteState = {
                query: routeState.query,
                page: routeState.page
            };

            // Loop through the properties from the route and map them to the state
            for (let prop in routeState) {
                if (validRefinements.indexOf(prop) > -1) {
                    // Get the attribute name that needs to be assigned on the index
                    let refinementAttribute = (prop in refinementsMap) ? refinementsMap[prop] : prop;

                    // is this a conjunctive refinement?
                    if (conjunctiveRefinements.indexOf(prop) > -1) {
                        menu = Object.assign({}, menu, {
                            [refinementAttribute]: routeState[prop]
                        });
                    } else {
                        refinementList = Object.assign({}, refinementList, {
                            [refinementAttribute]: routeState[prop]
                        });
                    }

                }
            }

            // Merge refinements into the route state if any
            if (Object.keys(menu).length) {
                returnRouteState = Object.assign({}, returnRouteState, { menu });
            }

            if (Object.keys(refinementList).length) {
                returnRouteState = Object.assign({}, returnRouteState, { refinementList });
            }

            return {
                [indexName]: returnRouteState
            };
        }
    };
};

export default function SearchRouting(indexName) {
    return {
        router,
        stateMapping: stateMapping(indexName)
    };
}