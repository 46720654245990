export default class Share  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    openShareWindow(e) {
        e.preventDefault();

        const $el = $(e.currentTarget);

        //Get page url and title
        var pageURL = encodeURI(window.location.href);
        var pageTitle = encodeURI(document.title);

        // Get share url
        var targetURL = $el.attr('href');

        //Replace the unknown title and url values in the target url with actual values
        var mapObj = {
            '_TITLE_':pageTitle,
            '_URL_':pageURL,
        };
        var targetURLNew = targetURL.replace(/_URL_|_TITLE_/g, function(matched){
            return mapObj[matched];
        });

        // Get target
        var target = $el.attr('target');

        // Get options
        var options = $el.attr('data-options');

        // Open share window
        window.open(targetURLNew, target, options);
    }

    printWindow(e) {
        e.preventDefault();

        window.print();
    }


    initListeners() {
        var $share = $.hook('share-social');
        $share.on('click', this.openShareWindow.bind(this));

        var $print = $.hook('print-social');
        $print.on('click', this.printWindow.bind(this));
    }
}
