export default class TeamCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    teamSlick() {
        $.hook('team-carousel').each(function(index, obj){
            const $teamModule = obj;
            const $teamSlides = $('.team-slides', $teamModule)[0];
            const $prev = $('.slide-circular-prev', $teamModule);
            const $next = $('.slide-circular-next', $teamModule);
            const teamSlidesLength = $('.team-slide', $teamModule).length;
            let centerMode;
            let initialSlide;

            if ($teamSlides && $teamSlides.id) {
                const $teamSlidesInstance = $(`#${$teamSlides.id}`);

                if (teamSlidesLength > 4) {
                    initialSlide = 0;
                } else {
                    initialSlide = 1;
                }

                //initialize slick
                $teamSlidesInstance.slick({
                    infinite: teamSlidesLength > 4,
                    draggable: teamSlidesLength > 4,
                    arrows: teamSlidesLength > 4,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: $prev,
                    nextArrow: $next,
                    centerMode: true,
                    initialSlide: initialSlide,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 1440,
                            settings: {
                                infinite: true,
                                draggable: true,
                                arrows: true,
                            }
                        }
                    ]
                });
            }
        });
    }

    showBio(e) {
        const $el = $(e.currentTarget);
        $el.addClass('showBio');
    }

    hideBio(e) {
        const $el = $(e.currentTarget);
        $el.removeClass('showBio');
    }

    initListeners() {
        this.teamSlick();
        const isTouchDevice = ('ontouchstart' in window || 'navigator.maxTouchPoints' in window);

        if (!isTouchDevice) {
            const $el = $.hook('TeamSlide');

            $el.on('mouseenter', this.showBio.bind(this));
            $el.on('mouseleave', this.hideBio.bind(this));
        }
    }
}