export default class PersistentNav {
    constructor(App) {
        this.app = App;

        this.$nav = $.hook('persistent-nav');
        this.$navMenu = $.hook('persistent-nav-menu');
        this.$sectionName = $('.persistent-nav-menu a', this.$nav);
        this.$mainContainer = $.hook('main');
        this.$mobileActiveSection = $('.persistent-nav-menu .current-item span', this.$nav);
        this.$titleBar = $.hook('title-bar');
        this.$toggleShare = $.hook('share-trigger');
        this.fixedClass = 'persistent-nav-fixed';
        this.openClass = 'persistent-nav-open';
        this.linkBarClass = 'link-bar';
        this.titleBarHeight = 0;
        this.isMobile = false;

        // Bail early if nav element doesnt exist
        if (!this.$nav.length) {
            return;
        }

        if (this.$nav.length) {
            //prevents error on pages without persistent nav. maybe a better solution exists.
            this.originalOffset = this.$nav.offset().top;
        }
        this.isNavFixed = false;
        this.initListeners();
    }

    initListeners() {
        const { modules } = this.app;

        this.app.on(modules.Header.constructor, 'HEADER_FIXED', this.onHeaderFixed.bind(this));

        // Ensure nav is in the right position on resize
        this.app.$win.on('resize', $.debounce(this.onResize.bind(this), 250));

        // Make nav sticky on scroll
        this.app.$win.on('load scroll', this.onScroll.bind(this));

        this.$nav.on('click', this.onMobileClick.bind(this));

        $('.persistent-nav-menu a', this.$nav).on('click', this.updateMobileChapter.bind(this));

        this.$navMenu.on('update.zf.magellan', this.updateMobileChapter.bind(this));

        // keep track of width
        this.isMobile = (this.app.$win.width() < 861);

        //toggle view of share options
        this.$toggleShare.on('click', this.toggleShareOptions.bind(this));
    }

    onMobileClick(e) {
        //toggle the dropdown for mobile devices
        //only if more than one link
        if (!this.$nav.hasClass(this.linkBarClass)) {
            //only preventDefault if we are NOT showing a "Link Bar" type nav
            e.preventDefault();
        }

        if (this.$sectionName.length > 1) {
            this.$nav.toggleClass(this.openClass);

            if (this.$nav.hasClass(this.openClass)) {
                this.$mobileActiveSection.addClass('hide');
            } else {
                setTimeout(() => {
                    this.$mobileActiveSection.removeClass('hide');
                }, 350);
            }
        }
    }

    updateMobileChapter(e) {
        //for mobile view, update the visible chapter title when clicked
        const $el = $(e.currentTarget);
        const activeTitle = $el.find('.is-active').text() || 'Overview';

        this.$mobileActiveSection.text(activeTitle);
    }

    onHeaderFixed() {
        if (!this.titleBarHeight) {
            this.titleBarHeight = this.$titleBar.outerHeight();
        }
    }

    onResize() {
        // Update header height
        this.titleBarHeight = this.$titleBar.outerHeight();

        if (this.app.modules.Header.isHeaderFixed) {
            this.lockNav();
        }
    }

    /**
     * Lock the navigation once it reaches the bottom of the header
     */
    onScroll() {

        // close it
        this.$nav.removeClass(this.openClass);

        const top = this.titleBarHeight + this.app.$win.scrollTop();

        if (top >= this.originalOffset) {
            if (!this.isNavFixed) {
                this.lockNav();
                this.addPadding();
            }
            this.isNavFixed = true;
        } else {
            if (this.isNavFixed) {
                this.unlockNav();
                this.removePadding();
            }
            this.isNavFixed = false;
        }
    }

    lockNav() {
        // always 50px on mobile
        const navHeight = this.isMobile ? 50 : this.$nav.height();

        this.$nav.addClass(this.fixedClass);
        this.$nav.css('top', this.titleBarHeight);

        // Update Magellan's positions points
        this.reflowMagellan();
    }

    unlockNav() {
        this.$nav.removeClass(this.fixedClass);
        this.$nav.removeAttr('style');

        // Update Magellan's positions points
        this.reflowMagellan();
    }

    addPadding() {
        // always 50px on mobile
        const navHeight = this.isMobile ? 50 : this.$nav.height();

        // Check if the main container has any padding applied
        let paddingTop = Math.round(parseInt(this.$mainContainer.css('padding-top')));

        // Save this value for when unlock if it has any
        if (paddingTop) {
            this.$mainContainer.data('padding-top', paddingTop);
        }
        // Now combine paddings
        paddingTop = paddingTop + navHeight;

        // Add padding to main container
        this.$mainContainer.css('padding-top', paddingTop);
    }

    removePadding() {
        // Check if the main container has any padding saved
        const paddingTop = this.$mainContainer.data('padding-top');

        if (paddingTop) {
            this.$mainContainer.css('padding-top', paddingTop);
        } else {
            this.$mainContainer.removeAttr('style');
        }
    }

    reflowMagellan() {
        if (this.$navMenu.length) {
            this.$navMenu.foundation('reflow');
        }
    }

    toggleShareOptions(e) {
        const $el = $(e.currentTarget);

        this.$toggleShare.parent().toggleClass('openShare');

    }
}