import { DefaultSearch }  from '@modules/search';

export default class BlogsSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $searchResultsTemplate      = $.hook('blogs-results-item-template').remove();

        this.$blogsSearchBar        = $.hook('blogs-search-bar');
        this.$blogsSearchResults    = $.hook('blogs-search-results');
        this.$searchBarForm               = $.hook('search-bar');

        this.searchResultsTemplate        = $searchResultsTemplate.html();
        this.searchBoxInputStr            = $.hookStr('search-box-input');
        this.searchResultItemsStr         = $.hookStr('search-results-items');

        this.attributesToFilterBy         = ['topicsBlog'];

        // Bail out early if perspectives search does not exist
        if (!this.$blogsSearchBar.length && !this.$blogsSearchResults.length) {
            return;
        }

        this.setupFilters();

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        // Use the date descending index
        this.INDEX_NAME = `${this.INDEX_NAME}_date_desc`;

        // Custom configuration for perspectives search
        this.config = {
            hitsPerPage: 6,
            filters: 'templateName:Blog',
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        this.widgets = [
            {
                searchBox: {
                    container: this.$blogsSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                currentRefinements: {
                    container: this.$selectedFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderCurrentRefinements.bind(this)
                }
            }, {
                clearRefinements: {
                    container: this.$clearFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderClearRefinements.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$blogsSearchResults[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.searchResultsTemplate
                    }
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this),
                }
            }
        ];

        this.init();

        this.afterSearchInit();
    }

    afterSearchInit() {
        this.$blogsSearchBar.addClass('search-active');

        // Scroll to search if query
        this.scrollToSearch(this.$blogsSearchBar);
    }
}
