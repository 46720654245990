export default class AwardsCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    awardsSlides() {
        $.hook('award-carousel').each(function(index, obj){
            const $window = $(window);
            const $awardModule = obj;
            const $awardSlides = $('.award-slides', $awardModule)[0];
            const $awardSlidesItems = $('.award-slide', $awardModule);
            const $prev = $('.slide-prev', $awardModule);
            const $next = $('.slide-next', $awardModule);
            let reset = false;
            let windowWidth;
            let currentSlide;

            if ($awardSlides && $awardSlides.id) {
                const $awardSlidesInstance = $(`#${$awardSlides.id}`);

                // reset the timer when the slide changes
                $awardSlidesInstance.on('beforeChange', function(){
                    $('.slider-countdown', $awardModule).removeClass('countingDown');
                });

                // restart the timer when the slide changes
                $awardSlidesInstance.on('afterChange', function(){
                    $('.slider-countdown', $awardModule).addClass('countingDown');
                });

                $awardSlidesInstance.slick({
                    slidesToScroll: 1,
                    pauseOnHover: false,
                    prevArrow: $prev,
                    nextArrow: $next,
                    slidesToShow: 4,
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                autoplay: true,
                                slidesToShow: 1
                            }
                        }
                    ]
                });

                $window.on('resize', function(){
                    windowWidth = $window.innerWidth;
                    if (innerWidth >= 768 && !reset) {
                        currentSlide = $awardSlidesInstance.slick('slickCurrentSlide');
                        if (currentSlide !== 0) {
                            // reset to 0 and make sure autoplay stops
                            $awardSlidesInstance.slick('slickGoTo', 0);
                            $awardSlidesInstance.slick('slickPause');
                        }
                        reset = true;
                    } else if (innerWidth < 768) {
                        reset = false;
                    }
                });                    
            }
        });
    }

    initListeners() {
        this.awardsSlides();
    }
}