import { DefaultSearch }  from '@modules/search';

export default class TeamSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $teamMemberTemplate = $.hook('search-results-item-template').remove();

        this.$teamSearchBar            = $.hook('team-search-bar');
        this.$teamSearchResults        = $.hook('team-search-results');
        this.$teamTabs                 = $.hook('listing-tabs');
        this.$alphaFilter              = $.hook('alpha-filters');
        this.$executiveText            = $.hook('executive-text');

        this.alpha                     = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        this.teamMemberTemplate        = $teamMemberTemplate.html();
        this.searchBoxInputStr         = $.hookStr('search-box-input');
        this.searchResultItemsStr      = $.hookStr('search-results-items');

        // Bail out early if team search does not exist
        if (!this.$teamSearchBar.length || !this.$teamSearchResults.length) {
            return;
        }

        this.attributesToFilterBy      = [].concat(this.getFilterAttributes(), 'lastNameFirstLetter');

        this.setupFilters();

        // JS Templates
        this.templates = {
            hits: {
                item: this.teamMemberTemplate
            }
        };

        // Custom configuration for team search
        this.config = {
            hitsPerPage: 18,
            filters: 'templateName:TeamMember',
            restrictSearchableAttributes: [
                'fullName',
                'bio',
                'differentiation',
                'impact',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Setup the config for each widget
        this.widgets = [
            {
                searchBox: {
                    container: this.$teamSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    queryHook: this.onQueryHook.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$teamSearchResults.find(this.searchResultItemsStr)[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.teamMemberTemplate
                    }
                },
            }, {
                currentRefinements: {
                    container: this.$selectedFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderCurrentRefinements.bind(this)
                }
            }, {
                clearRefinements: {
                    container: this.$clearFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderClearRefinements.bind(this)
                }
            }, {
                // For tabs
                menu: {
                    container: this.$teamTabs[0],
                    attribute: 'roles',
                    sortBy: ['name:asc'],
                    renderFunc: this.renderTabs.bind(this)
                }
            }, {
                // For alpha-filters
                menu: {
                    container: this.$alphaFilter[0],
                    attribute: 'lastNameFirstLetter',
                    renderFunc: this.renderAlphaMenu.bind(this),
                    limit: 30
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        this.init();
    }

    /**
     * Render team type tabs
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderTabs(renderOptions, isFirstRender) {
        const { items, createURL, refine } = renderOptions;
        const $tabs = this.$teamTabs.find('a');

        if (isFirstRender) {
            $tabs.on('click', event => {
                event.preventDefault();
                const { tabType } = event.currentTarget.dataset;

                refine(tabType);
            });

            
            // Start with "Executive Team" tab as active
            if (location.search === '' || location.search === null) {
                const $executiveTab = $tabs.filter('[data-tab-type="Executive Team"]').attr('href', createURL('Executive Team'));
                setTimeout(() => {
                    $executiveTab.click();
                }, 100);
            }
        }

        if (items.length) {
            const hasRefinements = items.find(item => item.isRefined) || false;

            // Are we displaying "all" or "refined" items?
            if (hasRefinements) {
                this.$teamSearchResults.removeClass('all').addClass('refined');
            } else {
                this.$teamSearchResults.removeClass('refined').addClass('all');
            }

            // Update active items and urls
            $tabs.each((i, el) => {
                const $tab = $(el);
                const { tabType } = $tab.data();
                const tabData = items.find(item => item.value === tabType) || false;

                // Remove active class
                $tab.removeClass('activeTab');

                // Add class if refined
                if (tabData) {
                    $tab.attr('href', createURL(tabData.value))
                        .addClass(tabData.isRefined ? 'activeTab' : '');
                } else {
                    // This is "all" tab
                    $tab.attr('href', createURL(''))
                        .addClass(!hasRefinements ? 'activeTab' : '');
                }
            });

            // Update "Executive text"
            if (this.$executiveText.length) {
                this.$executiveText.each((i, el) => {
                    const $tab = $(el);
                    const { tabType } = $tab.data();
                    const tabData = items.find(item => item.value === tabType) || false;

                    // Remove active class
                    $tab.addClass('hide');

                    // Add class if refined
                    if (tabData) {
                        $tab.removeClass(tabData.isRefined ? 'hide' : '');
                    }
                });
            }
        }
    }

    /**
     * Render alpha menu to sort team members by first letter of their last name
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderAlphaMenu(renderOptions) {
        const { items, createURL, refine, widgetParams } = renderOptions;

        if (items.length) {
            // Map letters so all letters are always shown
            const allLetters = this.alpha.map(letter => items.find(item => item.value === letter) || { value: letter });

            widgetParams.container.innerHTML = allLetters.map(item => `
                <a href="${createURL(item.value)}" class="alpha-filter ${!item.count ? 'disabled' : ''} ${item.isRefined ? 'active' : ''}" data-filter="${item.value}">${item.value}</a>
            `).join('');

            // Refine on click
            this.$alphaFilter.find('a').on('click', event => {
                event.preventDefault();

                refine(event.currentTarget.dataset.filter);
            });
        }
    }

    /**
     * Custom Transform to show "Kevin McCarty" first when he appears in the results
     */
    _transformHits(items) {
        const hits = DefaultSearch.prototype._transformHits.call(this, items);
        let kevin;
        const kevinIndex = hits.findIndex(h => h.fullName === 'Kevin McCarty');

        if (kevinIndex >= 0) {
            kevin = hits.splice(kevinIndex, 1)[0];

            if (kevin) {
                hits.unshift(kevin);
            }
        }

        return hits;
    }
}
