import '../scss/main.scss';

// Used for babel-polyfills
import 'core-js';

import App from '@core/app';

import Analytics from '@modules/analytics';
import AwardsCarousel from '@modules/awards-carousel';
import ContentCarousel from '@modules/content-carousel';
import FullWidthContentCarousel from '@modules/full-width-content-carousel';
import Countdown from '@modules/countdown';
import DataListWithImages from '@modules/data-list-with-images';
import Dropdown from '@modules/dropdown';
import FeatureServiceBlock from '@modules/feature-service-block';
import FeatureBlocks from '@modules/feature-blocks';
import FooterSubscribe from '@modules/footer-subscribe';
import FourupCarousel from '@modules/fourup-carousel';
import ImageQuoteSlideout from '@modules/image-quote-slideout';
import Greenhouse from '@modules/greenhouse';
import LargeMediaCarousel from '@modules/large-media-carousel';
import LargeServices from '@modules/large-services';
import GlobalUpdates from '@modules/global-updates';
import OffsetCardCarousel from '@modules/offset-card-carousel';
import PersistentNav from '@modules/persistent-nav';
import RotatingFeaturedVideo from '@modules/rotating-featured-video';
import TeamCarousel from '@modules/team-carousel';
import TestimonialCarousel from '@modules/testimonial-carousel';
import EventTables from '@modules/tables';
import PostMessageListener from '@modules/pardot-forms';
import Share from '@modules/share';
import QuoteImageCarousel from '@modules/quote-image-carousel';
import ImageCardCarousel from '@modules/image-card-carousel';
import ReadTime from '@modules/read-time';
import ImageSideTextCarousel from '@modules/image-side-text-carousel';
import Video from '@modules/video';

// Search Modules
import {
    AlgoliaList,
    BlogsSearch,
    CareersSearch,
    ClientStoriesSearch,
    GlobalSearch,
    JobsSearch,
    NewsSearch,
    OfferingsSearch,
    PerspectivesSearch,
    TeamSearch } from '@modules/search';

const WestMonroeApp = new App({
    modules: {
        Analytics,
        AwardsCarousel,
        ContentCarousel,
        Countdown,
        DataListWithImages,
        Dropdown,
        EventTables,
        FeatureServiceBlock,
        FeatureBlocks,
        FooterSubscribe,
        FourupCarousel,
        FullWidthContentCarousel,
        Greenhouse,
        ImageQuoteSlideout,
        LargeMediaCarousel,
        LargeServices,
        GlobalUpdates,
        PersistentNav,
        RotatingFeaturedVideo,
        TeamCarousel,
        OffsetCardCarousel,
        TestimonialCarousel,
        Share,
        QuoteImageCarousel,
        ImageCardCarousel,
        ImageSideTextCarousel,
        ReadTime,
        Video,

        // Search
        AlgoliaList,
        BlogsSearch,
        CareersSearch,
        GlobalSearch,
        JobsSearch,
        NewsSearch,
        OfferingsSearch,
        PerspectivesSearch,
        TeamSearch,
        ClientStoriesSearch,
        PostMessageListener,
    },
    foundationPlugins: {}
});

window.WestMonroe = window.WestMonroe || {};
window.WestMonroe.App = WestMonroeApp || {};
