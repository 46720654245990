export default class ImageQuoteSlideout {
    constructor(App) {
        this.app = App;
        this.$quoteItems = $.hook('image-quote-slideout-item');

        if (!this.$quoteItems.length) {
            return;
        }

        this.initListeners();
    }

    initListeners() {
        this.$quoteItems.hover((e) => {
            $(e.currentTarget).addClass('active');
        });
    }
}