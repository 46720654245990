import DataDriven from '@happycog/data-driven';

export default class Analytics {
    constructor(App) {
        this.app = App;
        this.dd = new DataDriven({
            service: 'gtm',
            prefix: 'ga',
            autorun: false,
        });

        this.app.dd = this.dd;

        this.app.on(this.app, 'APP_DOC_READY', this.initListeners.bind(this));
    }

    initListeners() {
        this.onDataDrivenEmit();
        this.app.on(
            this.app.modules.PostMessageListener.constructor,
            'FORM_LOADED',
            this.onPardotFormLoaded.bind(this)
        );
    }

    onDataDrivenEmit() {
        // Rename event properties then emit the event
        this.dd.onEmit(event => {
            const { properties } = event;
            delete properties.bind;

            // GA wants empty properties as undefined
            Object.keys(properties).map(prop => {
                if (properties[prop] === '') {
                    properties[prop] = undefined;
                }
            });

            // Emit the event
            event.emit();
        });
    }

    onPardotFormLoaded(event, data) {
        const $iframe = this.getIframeById(data.frame).get(0);

        // Sent the GTM Initialized data back to the iframe
        if ('gtmDataInitialized' in window && typeof $iframe !== 'undefined') {
            if ($iframe.contentWindow && $iframe.contentWindow.postMessage) {
                $iframe.contentWindow.postMessage({
                    message: 'wmjp_gtm_data_initialized',
                    data: window.gtmDataInitialized,
                    frame: data.frame
                }, '*');
            }
        }
    }

    getIframeById(frameId) {
        return $.hook('pardot-form').find('iframe[name="'+frameId+'"]');
    }
}
