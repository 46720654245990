
export default class ListenerHelpers {

    constructor(App) {
        this.app = App;
    }

    getIframeById(frameId) {
        return $.hook('pardot-form').find('iframe[name="'+frameId+'"]');
    }

    getPardotFormByIframeId(frameId) {
        return this.getIframeById(frameId).closest('[data-form-name]');
    }

    basicFormEvent(eventName, message) {
        if(typeof message != 'undefined' && typeof message.frame !== 'undefined'){
            var dataEl = this.getPardotFormByIframeId(message.frame);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: eventName,
                formName: dataEl.attr('data-form-name'),
                formType: dataEl.attr('data-form-type'),
                userEmail: message.data.userEmail
            });
        } else {
            // console.log(message, message.frame);
        }
    }
}