import { DefaultSearch } from '@modules/search';

export default class AlgoliaList extends DefaultSearch {
    constructor(App) {
        super(App);

        const $searchResultsTemplate      = $.hook('algolia-list-results-item-template').remove();

        this.$algoliaListSearchResults    = $.hook('algolia-list-search-results');

        this.searchResultsTemplate        = $searchResultsTemplate.html();
        // this.searchBoxInputStr            = $.hookStr('search-box-input');
        this.searchResultItemsStr         = $.hookStr('search-results-items');

        this.algoliaFilterFormat          = 'This Is Digital';

        // Bail out early if perspectives search does not exist
        if (!this.$algoliaListSearchResults.length) {
            return;
        }

        this.setupFilters();

        // Use the date descending index
        this.INDEX_NAME = `${this.INDEX_NAME}_date_desc`;

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        // Limit search to client stories
        this.searchTemplate = 'Perspective';

        // Custom configuration for client stories search
        this.config = {
            hitsPerPage: 6,
            filters: `templateName:${this.searchTemplate} AND topicsCorporate:'${this.algoliaFilterFormat}'`,
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Setup the config for each widget
        this.widgets = [
            {
                searchBox: { virtual: true }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$algoliaListSearchResults[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.searchResultsTemplate
                    }
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        this.init();

    }
}