export default class Header {
    constructor(App) {
        this.app = App;

        this.$body = $('body');
        this.reverseNavClass = 'reverse-main-nav';

        // Header
        this.$header = $.hook('header');
        this.headerFixed = 'header-fixed';
        this.headerInitHeight = this.$header.height();
        this.scrollStreshold = 100;
        this.isHeaderFixed = false;

        // Off-canvas
        this.offCanvasMenuStr = $.hookStr('off-canvas-main-menu');

        // Menu Search
        this.$searchOpenToggle = $.hook('main-search-open-toggle');
        this.$searchCloseToggle = $.hook('main-search-close-toggle');
        this.$searchContent = $.hook('main-search-content');
        this.searchOpen = false;

        // Top search
        this.$navSearchOpenToggle = $.hook('header-nav-search-open-toggle');
        this.$navSearchCloseToggle = $.hook('header-nav-search-close-toggle');
        this.$navSearch = $.hook('header-nav-search');
        this.$navSearchField = this.$navSearch.find($.hookStr('header-nav-search-field'));

        // Drilldown
        this.$navMainDrilldown = $.hook('nav-main-drilldown');
        this.$navSocial = $.hook('nav-social');
        this.$navSecondary = $.hook('nav-secondary');
        this.secondaryMenuVisible = true;

        this.initListeners();
    }

    initListeners() {
        // Open/Close Menu search
        this.$searchOpenToggle.on('click', this.toggleSearch.bind(this));
        this.$searchCloseToggle.on('click', this.toggleSearch.bind(this));

        // Open/Close nav search
        this.$navSearchOpenToggle.on('click', e => {
            e.preventDefault();
            this.$navSearch.addClass('open');
            this.$navSearchField.focus();
        });
        this.$navSearchCloseToggle.on('click', e => {
            e.preventDefault();
            this.$navSearch.removeClass('open');
        });

        // Off-canvas
        // These events should be delegated for them to work
        this.$body.on('openedEnd.zf.offCanvas', this.offCanvasMenuStr, this.onOffCanvasOpen.bind(this));
        this.$body.on('closed.zf.offCanvas', this.offCanvasMenuStr, this.onOffCanvasClose.bind(this));

        // Drilldown on init
        this.$navMainDrilldown.on('init.zf.drilldown', this.updateDrilldownBackLink.bind(this));
        this.$navMainDrilldown.on('init.zf.drilldown', this.openActiveMenu.bind(this));
        this.$navMainDrilldown.on('open.zf.drilldown', this.onDrilldownOpen.bind(this));
        this.$navMainDrilldown.on('hide.zf.drilldown', this.onDrilldownClose.bind(this));

        // Change header color on scroll
        this.app.$win.on('load scroll', this.onHeaderScroll.bind(this));
    }

    toggleSearch(e) {
        e.preventDefault();

        if (!this.searchOpen) {
            this.openSearch();
        } else {
            this.closeSearch();
        }

        this.searchOpen = !this.searchOpen;
    }

    openSearch() {
        this.$searchContent.css('transform', 'translate(0%)');
        // Focus search input on a timeout to allow the animation to happen
        setTimeout(() => {
            this.$searchContent.find('input').focus();
        }, 300);
    }

    closeSearch() {
        this.$searchContent.css('transform', 'translate(105%)');
    }

    /**
     * Loop through all Drilldown menu items and replace the `Back` link
     * with the sub-navigation's parent link
     */
    updateDrilldownBackLink() {
        this.$navMainDrilldown.find('.js-drilldown-back').each((i, el) => {
            const $el = $(el);
            const $parent = $el.parents('.is-drilldown-submenu-parent').first();

            // If it's a sub-menu-item, let's add the name of the parent
            if ($parent.hasClass('is-submenu-item')) {
                const text = $parent.find('> a').text();
                $el.find('a').text(text);
            } else {
                // If it's not a sub-menu-item it must be at root level
                $el.find('a').text('Main Menu');
            }
        });
    }

    openActiveMenu() {
        // Get the last active elements if any
        const $active = this.$navMainDrilldown.find('.active').last();

        if ($active.length) {
            // Get the active item's inmediate parent menu
            const $subMenu = $active.parents('.menu.submenu');

            // Open the sub menu
            this.$navMainDrilldown.foundation('_showMenu', $subMenu);
        }
    }

    onHeaderScroll() {
        const top = this.app.$win.scrollTop();

        if (top >= this.scrollStreshold) {
            if (!this.isHeaderFixed) {
                this.$header.addClass(this.headerFixed);
                if (this.$body.hasClass(this.reverseNavClass)) {
                    // Add padding to the element after the header matching it's height
                    // for a smoother scroll transition
                    this.$header.next().css('padding-top', this.headerInitHeight);
                }
                this.app.dispatch(Header, 'HEADER_FIXED');
            }
            this.isHeaderFixed = true;
        } else {
            if (this.isHeaderFixed) {
                this.$header.removeClass(this.headerFixed);
                if (this.$body.hasClass(this.reverseNavClass)) {
                    this.$header.next().removeAttr('style');
                }
                this.app.dispatch(Header, 'HEADER_UNFIXED');
            }
            this.isHeaderFixed = false;
        }
    }

    onDrilldownOpen(event) {
        // Hide social media and secondary menu if it's visible
        if (this.secondaryMenuVisible) {
            this.$navSocial.addClass('drilldown-open');
            this.$navSecondary.addClass('drilldown-open');

            this.secondaryMenuVisible = false;
        }
    }

    onDrilldownClose(event, $el) {
        if (!this.secondaryMenuVisible) {
            // This must be the root item, let's show the secondary and social navigation
            if (!$el.parent().hasClass('is-drilldown-submenu-item')) {
                this.$navSocial.removeClass('drilldown-open');
                this.$navSecondary.removeClass('drilldown-open');

                this.secondaryMenuVisible = true;
            }
        }
    }

    onOffCanvasOpen() {
        this.app.lock();
    }

    onOffCanvasClose() {
        this.app.unlock();
    }
}