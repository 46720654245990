export default class Dropdown  {
    constructor(App) {
        this.app = App;
        this.initListeners();
        this.DropdownContainer = $.hook('dropdown-container');
    }

    toggleDropdown(e) {
        const $target = $(e.target);

        if (!$target.hasClass('goToLink')){
            $('.dropdown', this.DropdownContainer).toggleClass('openDropdown');
        }
    }

    goToDropdown(e) {
        e.preventDefault();

        const $el = $(e.currentTarget);
        const link = $.hook('go-to-link');

        var src = $el.attr('data-link');
        var linkName = $el.attr('data-link-name');

        $('.activeItem', this.DropdownContainer).text(linkName);
        link.attr('href', src);
    }

    goToTab(e) {
        const $el = $(e.currentTarget);
        var tabName = $el.attr('data-tab');
        var tabText= $el.text();

        $el.addClass('activeTab').siblings().removeClass('activeTab');
        $('.tab-content[data-tab="'+tabName+'"]', this.DropdownContainer).addClass('activeTab').siblings().removeClass('activeTab');
        $('.activeItem', this.DropdownContainer).text(tabText);
    }

    initListeners() {
        var $el = $.hook('dropdown-container');
        $el.on('click', this.toggleDropdown.bind(this));
        $('.dropdown-help-item', $el).on('click', this.goToDropdown.bind(this));
        $('.tab-item', $el).on('click', this.goToTab.bind(this));

    }
}