export default class QuoteImageCarousel  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initCarousels() {
        $.hook('quote-image-carousel').each(function(index, obj){
            const $QuoteImageModule = obj;
            const $QuoteImageSlides = $('.quote-image-slides', $QuoteImageModule)[0];
            const $prev = $('.slide-prev', $QuoteImageModule);
            const $next = $('.slide-next', $QuoteImageModule);

            if ($QuoteImageSlides && $QuoteImageSlides.id) {
                const $QuoteImageSlidesInstance = $(`#${$QuoteImageSlides.id}`);

                // reset the timer when the slide changes
                $QuoteImageSlidesInstance.on('beforeChange', function(){
                    $('.slider-countdown', $QuoteImageModule).removeClass('countingDown');
                });

                // restart the timer when the slide changes
                $QuoteImageSlidesInstance.on('afterChange', function(){
                    $('.slider-countdown', $QuoteImageModule).addClass('countingDown');
                });

                // initialize slick
                $QuoteImageSlidesInstance.slick({
                    centerMode: false,
                    variableWidth: false,
                    centerPadding: 0,
                    prevArrow: $prev,
                    nextArrow: $next,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: false
                });
            }
        });
    }

    initListeners() {
        this.initCarousels();
    }
}