export default class DataListWithImages {
    constructor(App) {
        this.app = App;
        this.$images = $.hook('data-list-image-wrapper');
        this.$dataItems = $.hook('data-list-item');

        // Bail early
        if (!this.$images.length) {
            return;
        }

        this.initListeners();
    }

    initListeners() {
        this.$dataItems.hover(inEvent => {
            this.onListHoverIn(inEvent);
        });
    }

    onListHoverIn(e) {
        const $el = $(e.currentTarget);
        const index = $el.data('index');
        const $newImage = $(this.$images[index]);
        this.$images.removeClass('active');

        if ($newImage.length) {
            $newImage.addClass('active');
        }
    }
}