export default class EventTables {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }
    loadTables() {
        this.table = $.hook('event-tables-table');
        this.tbody = this.table.find('tbody');
        const tableContainer = $.hook('event-tables');
        const heading = tableContainer.find('.table-heading');

        var groupColumn = 2;
        var table = this.table.DataTable({
            paging: true,
            language: {
                paginate: {
                    previous: '&lsaquo;',
                    next: '&rsaquo;'
                }
            },
            bLengthChange: false,
            bInfo: false,
            searching: true,
            columnDefs: [
                { visible: false, targets: groupColumn }
            ],
            order: [[ groupColumn, 'asc' ]],
            displayLength: 25,
            drawCallback: function (  ) {
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;

                api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group ) {
                        $(rows).eq( i ).before(
                            '<tr class="location-title"><td colspan="5"><h3 class="headline3 text-downriver">'+group+'</h3></td></tr><tr class="header-row"><th>Name</th><th>Date</th><th>Type</th><th>Location</th><th>Category</th></tr>'
                        );

                        last = group;
                    }
                } );
            },
            initComplete: function() {
                var api = this.api();

                api.columns().every(function() {
                    var column = this;

                    if (column.index() === 0) {
                        // Create a filter for universities only
                        var select = $('<select class="eyebrow"><option value="">All Universities</option></select>')
                            .appendTo(heading)
                            .on('change', function() {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                if (column.index() === 0) {
                                    column.column([2]).search(
                                        val ? '^' + val + '$' : '',
                                        true,
                                        false
                                    ).draw();
                                }
                            });

                        if (column.index() === 0) {
                            this.column([2]).data().unique().sort().each(function(d) {
                                /**
                                 * I should probably investigate where this data
                                 * is coming from and why one of the options is
                                 * empty, but I didn't write this code and I
                                 * only have so much time. So I will accept the
                                 * shame heaped upon me for this -- TJ
                                 */
                                if (! d) {
                                    return;
                                }

                                select.append('<option value="' + d + '">' + d + '</option>');
                            }); //column.data
                        }
                    }
                });
            }
        } );

        table.on('draw', function() {
            /**
             * I honestly don't know what this was supposed to be doing, but
             * when "All Universities" was selected (a blank option), the
             * select would be emptied. And everything seems to work fine
             * without this code??? --TJ
             */
            // table.columns().indexes().each(function(idx) {
            //     var select = $(heading).find('select');
            //     if (select.val() === '' && idx !== 0) {
            //         select.empty().append('<option value=""/>');
            //         table.column(idx, {
            //             search: 'applied'
            //         }).data().unique().sort().each(function(d) {
            //             select.append('<option value="' + d + '">' + d + '</option>');
            //         });
            //     }
            // });
        });

        // Order by the grouping
        this.tbody.on( 'click', 'tr.group', function () {
            var currentOrder = table.order()[0];
            if ( currentOrder[0] === groupColumn && currentOrder[1] === 'asc' ) {
                table.order( [ groupColumn, 'desc' ] ).draw();
            }
            else {
                table.order( [ groupColumn, 'asc' ] ).draw();
            }
        } );

    }



    initListeners() {
        this.loadTables();
    }
}
