import { DefaultSearch }  from '@modules/search';

export default class NewsSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $newsTemplate          = $.hook('news-results-item-template').remove();

        this.$newsSearchBar          = $.hook('news-search-bar');
        this.$newsSearchResults      = $.hook('news-search-results');
        this.$searchBarForm          = $.hook('search-bar');

        this.isSearchLanding         = Boolean(this.$newsSearchBar.data('search-landing'));

        this.newsTemplate            = $newsTemplate.html();
        this.attributesToFilterBy    = [
            'industries.lvl0',
            'industries.lvl1',
            'topicsCorporate',
            'formatType',
            'dateMonth',
            'dateYear',
            'templateName'
        ];

        // Bail out early if perpectives search does not exist
        if (!this.$newsSearchBar.length && !this.$newsSearchResults.length) {
            return;
        }

        // This is a search landing page, let's setup the form and apply buttons
        if (this.isSearchLanding) {
            this.updateFormAction();
            this.updateFormSubmitButton();
        }

        this.setupFilters({
            isSearchLanding: this.isSearchLanding
        });

        // Use the date descending index
        this.INDEX_NAME = `${this.INDEX_NAME}_date_desc`;

        // JS templates
        this.templates = {
            hits: {
                item: this.newsTemplate
            }
        };

        // Limit search to newss
        this.searchTemplate = 'NewsArticle|Event|OffsiteNewsArticle';

        // Custom configuration for newss search
        this.config = {
            hitsPerPage: 9,
            filters: this.searchTemplate.split('|').map(item => `templateName:${item}`).join(' OR '),
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Default widgets config that should appear on search landing and search result pages
        // Since results are still being retrieved, these may need to be virtual widgets
        this.widgets = [
            {
                searchBox: {
                    container: this.$newsSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    virtual: this.isSearchLanding,
                    queryHook: this.onQueryHook.bind(this)
                }
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this),
                    virtual: this.isSearchLanding
                }
            }
        ];

        // These widgets config should only show on the search results page
        if (!this.isSearchLanding) {
            this.widgets = [
                {
                    stats: {
                        container: this.$stats[0],
                        renderFunc: this.renderStats.bind(this)
                    }
                }, {
                    // Virtual Widget to allow us to filter results without showing anything in the DOM
                    menu: {
                        attribute: 'templateName',
                        virtual: true
                    }
                }, {
                    currentRefinements: {
                        container: this.$selectedFilters[0],
                        includedAttributes: this.attributesToFilterBy,
                        renderFunc: this.renderCurrentRefinements.bind(this)
                    }
                }, {
                    clearRefinements: {
                        container: this.$clearFilters[0],
                        includedAttributes: this.attributesToFilterBy,
                        renderFunc: this.renderClearRefinements.bind(this)
                    }
                }, {
                    // Displaying results
                    hits: {
                        container: this.$newsSearchResults[0],
                        renderFunc: this.renderHits.bind(this),
                        templates: {
                            item: this.newsTemplate
                        }
                    },
                }
            ];
        }

        // Initialize the search
        this.init();

        this.afterSearchInit();
    }

    updateFormSubmitButton() {
        let inputFilled = false;
        let filtersSelected = false;

        // Show view all submit button
        this.$viewAll.removeClass('hide');

        // Check if we need to update the text
        this.$newsSearchBar.on('change keyup', 'input', event => {
            const input = event.currentTarget;
            const { type } = input;
            // Check for text and checkbox fields
            if (type === 'text') {
                inputFilled = (input.value !== '');
            } else {
                filtersSelected = Boolean(this.$newsSearchBar.find('input').filter(':checked').length);
            }

            // Update submit button text if search bar filled or filters selected|
            const submitText = (inputFilled || filtersSelected) ? 'Apply' : 'View All';
            this.$viewAll.text(submitText);
        });
    }

    afterSearchInit() {
        // Scroll to search if query
        this.scrollToSearch(this.$newsSearchBar);
    }
}
