export default class ImageSideTextCarousel  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initCarousels() {
        $.hook('image-side-text-carousel').each(function(index, obj){
            const $carouselSideTextModule = obj;
            const $carouselSideTextSlides = $('.carousel-side-text-slides', $carouselSideTextModule)[0];
            const $prev = $('.slide-circular-prev', $carouselSideTextModule);
            const $next = $('.slide-circular-next', $carouselSideTextModule);

            if ($carouselSideTextSlides && $carouselSideTextSlides.id) {
                const $carouselSideTextSlidesInstance = $(`#${$carouselSideTextSlides.id}`);


                // initialize slick
                $carouselSideTextSlidesInstance.slick({
                    centerMode: true,
                    variableWidth: false,
                    centerPadding: 0,
                    prevArrow: $prev,
                    nextArrow: $next,
                    pauseOnHover: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                });
            }
        
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    const target = entry.target;
                    if (entry.isIntersecting) {
                        target.classList.add('slidesIn');
                    }
                });
            });

            observer.observe(document.querySelector('.slides-wrapper'));
        });

    }

    initListeners() {
        this.initCarousels();
    }
}