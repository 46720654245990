// For the search only version
import algoliasearch from 'algoliasearch';
import instantsearch from 'instantsearch.js';
import * as ISWidget from 'instantsearch.js/es/widgets';
import * as connectors from 'instantsearch.js/es/connectors';
import { renderTemplate } from 'instantsearch.js/es/lib/utils';

/**
 * Search is powered by Algolia widgets. Different types of searches use
 * or extend this class for their particular use case.
 */
export default class AlgoliaClient {

    get widgets() {
        return this._widgets;
    }

    set widgets(widgets) {
        this._widgets = this._widgets.concat(widgets);
    }

    get config() {
        return this._config;
    }

    set config(config) {
        this._config = Object.assign({}, this.config, config);
    }

    constructor({routing}) {
        /**
         * @private
         * Widgets to add to instantsearch, none by default. For list of widgets
         * see here /node_modules/instantsearch.js/es/widgets/index.js
         * @type {Array}
         */
        this._widgets = [];

        /**
         * @private
         * Default configuration values for instantsearch
         */
        this._config = {
            hitsPerPage: 10
        };

        /**
         * Routing system for instantsearch
         * @type {[type]}
         */
        this.routing = routing;

        // The Index used for the search client
        this.INDEX_NAME = window.WestMonroe.GlobalConfig.AlgoliaIndex || 'dev_wm_sitecore';
    }

    init() {
        this._setupInstantSearch();
        this._setupWidgets();
        this.search.start();
    }

    setupClient() {
        // The Algolia Application ID.
        const _ALGOLIA_SEARCH_APP_ID = process.env.ALGOLIA_SEARCH_APP_ID || false;
        // The Algolia Api Key, make sure you use a "Search-Only API Key".
        const _ALGOLIA_SEARCH_API_KEY = process.env.ALGOLIA_SEARCH_API_KEY || false;

        // Bail if Algolia Keys don't exist
        if (!_ALGOLIA_SEARCH_APP_ID && !_ALGOLIA_SEARCH_API_KEY) {
            return console.error('Algolia API keys are not set properly.');
        }

        return algoliasearch(_ALGOLIA_SEARCH_APP_ID, _ALGOLIA_SEARCH_API_KEY);
    }

    static getIndex(indexName) {
        if (!indexName) {
            return console.error('Please provide an Index name');
        }

        const client = this.prototype.setupClient();
        return client.initIndex(indexName);
    }

    _setupInstantSearch() {
        // Init the search client.
        this.client = this.setupClient();

        // Setup instantsearch
        this.search = instantsearch({
            indexName: this.INDEX_NAME,
            searchClient: this.client,
            routing: this._getRouting(this.INDEX_NAME),
        });

        // Add instantsearch configure widget
        this.search.addWidgets([
            ISWidget.configure(this.config)
        ]);
    }

    /**
     * @private
     * Add widget to instant search
     */
    _setupWidgets() {
        // Loop through widgets and set them up
        $.each(this.widgets, (key, val) => {
            // Check if a config is passed
            const widgetName = typeof(val) === 'string' ? val : Object.keys(val)[0];

            // Make sure that it is a valid widget or skip it
            if (!Object.prototype.hasOwnProperty.call(ISWidget, widgetName) && !this._isCustomWidget(val)) {
                return true;
            }

            // Build widget to add to instantsearch
            this._buildWidget(widgetName, val);
        });
    }

    /**
     * @private
     * Build a widget function and config and add it to instantsearch
     * @param  {String} name
     * @param  {String|Object} config
     * @return {void}
     */
    _buildWidget(name, config) {
        const widgetConfig = this._getWidgetConfig(name, config);

        // If it's a custom widget let's build it and get out of here
        if (this._isCustomWidget(config)) {
            // Let's check that the actual widget function has been passed
            if (Object.prototype.hasOwnProperty.call(widgetConfig, 'widget') && typeof(widgetConfig.widget) === 'function') {
                const widgetFunc = widgetConfig.widget;
                this.search.addWidgets([
                    widgetFunc(widgetConfig)
                ]);
            }

            return;
        }

        const renderFunc = ('renderFunc' in widgetConfig) ? widgetConfig.renderFunc : null;
        const widgetFunc = this._getWidgetFunc({name, renderFunc, widgetConfig});

        // Instantiate the widget
        if (widgetFunc && (this._hasContainer(widgetConfig) || widgetConfig.virtual)) {
            this.search.addWidgets([
                widgetFunc(widgetConfig)
            ]);
        }
    }

    /**
     * @private
     * Check whether this is a custom widget or not
     * @param  {Mixed}  widget
     * @return {Boolean}
     */
    _isCustomWidget(widget) {
        // If it's a string there's no way it's custom
        if (typeof(widget) === 'string') {
            return false;
        }

        const widgetName = Object.keys(widget)[0];
        widget = widget[widgetName];

        return Object.prototype.hasOwnProperty.call(widget, 'custom') && widget.custom;
    }

    /**
     * @private
     * Get the widget function to add to instant search,
     * could be a default widget or a connector
     * @param  {String} name
     * @param  {Function} renderFunc
     * @return {Func}
     */
    _getWidgetFunc(widgetOptions) {
        const { name, renderFunc, widgetConfig } = widgetOptions;
        // Lets start with a default Instantsearch widget
        let widgetFunc = ISWidget[name];
        // A render function has been passed so we need a connector widget
        if (renderFunc || widgetConfig.virtual) {
            widgetFunc = this._getConnectorWidget(widgetOptions);
        }

        return widgetFunc;
    }

    /**
     * Get a connector widget
     * @param  {String} name
     * @param  {Function} renderFunc
     * @return {Func}
     */
    _getConnectorWidget(widgetOptions) {
        const { name, renderFunc, widgetConfig } = widgetOptions;
        const nameToCap = this.toCap(name);
        const connectorName = `connect${nameToCap}`;

        if (connectorName in connectors) {
            let connectorWidget;

            // This widget is virtual, no DOM
            if (('virtual' in widgetConfig) && widgetConfig.virtual) {
                connectorWidget = connectors[connectorName](() => null);
            } else {
                // This is a normal connector
                connectorWidget = connectors[connectorName](renderFunc);
            }

            return connectorWidget;
        }
    }

    /**
     * @private
     * Return the config for the widgets
     * @param  {String} name
     * @param  {Object} config
     * @return {Object}
     */
    _getWidgetConfig(name, config) {
        // At a minimum widgets will need a container so lets add that as a default
        let widgetConf = {
            container: `#${name}`
        };

        if (typeof(config) === 'object') {
            widgetConf = $.extend({}, widgetConf, config[name]);
        }

        // Check for config's container prop is the right type
        if (typeof(widgetConf.container) !== 'string' && !(widgetConf.container instanceof Element)) {
            console.error('"container" property must be a string or instanceof Element');
        }

        return widgetConf;
    }

    /**
     * @private
     * Check whether routing should be enable
     * @param  {Mixed} routing
     * @return {Boolean|Func}
     */
    _getRouting(indexName) {
        // Is this a custom routing?
        if (typeof(this.routing) === 'function') {
            return this.routing(indexName);
        }

        // Return instantsearch default routing or disable
        return (typeof(this.routing) === 'boolean') ? this.routing : false;
    }

    /**
     * --------------------------------------------------------------
     * HElPER METHODS
     * --------------------------------------------------------------
     */

    /**
     * @private
     * Check if the container property is set in the config and that it exists in the DOM
     * @param  {Object}  config
     * @return {Boolean}
     */
    _hasContainer(config) {
        return (('container' in config) && $(config.container).length);
    }

    /**
     * Returns a string whose first letter is capitalize
     * @param  {String} str
     * @return {String}
     */
    toCap(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Render a template
     * @param  {string} templates
     * @param  {Mixed} data
     * @return {Mixed}
     */
    render(templates, templateKey, data) {
        return renderTemplate({templates, templateKey, data});
    }

    static render(templates, templateKey, data) {
        return this.prototype.render(templates, templateKey, data);
    }

    /**
     * Converts HTML Characters into their escaped entities
     * @param  {String} str
     * @return {String}
     */
    escapeString(str) {
        return str
            .replace(' & ', ' &amp; ');
    }

    /**
     * Coverts escaped HTML Characters into their unescaped entities
     * @param  {[type]} str [description]
     * @return {[type]}     [description]
     */
    unescapeHtml(str) {
        return str
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '\\')
            .replace(/&#039;/g, '\'');
    }
}