export default class LargeMediaCarousel  {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    initCarousels() {
        $.hook('large-media-carousel').each(function(index, obj){
            const $largeMediaModule = obj;
            const $largeMediaSlides = $('.large-media-slides', $largeMediaModule)[0];

            if ($largeMediaSlides && $largeMediaSlides.id) {
                const $largeMediaSlidesInstance = $(`#${$largeMediaSlides.id}`);

                // reset the timer when the slide changes
                $largeMediaSlidesInstance.on('beforeChange', function(){
                    $('.slider-countdown', $largeMediaModule).removeClass('countingDown');
                });

                // restart the timer when the slide changes
                $largeMediaSlidesInstance.on('afterChange', function(){
                    $('.slider-countdown', $largeMediaModule).addClass('countingDown');
                });

                // initialize slick
                $largeMediaSlidesInstance.slick({
                    centerMode: true,
                    variableWidth: true,
                    centerPadding: 0,
                    lazyLoad: 'progressive',
                    pauseOnHover: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                autoplay: true,
                                autoplaySpeed: 5000,
                            }
                        }
                    ]
                });
            }
        });
    }

    initListeners() {
        this.initCarousels();
    }
}