import VideoPlayer from '@core/video-player';
import VideoUtility from '@core/video-player/video-utility';

export default class RotatingFeaturedVideo {
    constructor(App) {
        this.app = App;
        this.$rotatingFeaturedVideo = $.hook('rotating-featured-video');
        this.videoPlayerStr = $.hookStr('video-player');
        this.$videoWrapper = $.hook('video-wrapper');
        this.$videoSlides = $.hook('video-slides');

        // Bail early if elements don't exist
        if (this.$rotatingFeaturedVideo.length === 0) {
            return;
        }

        this.initSlickVideo();

    }

    initSlickVideo() {
        this.$rotatingFeaturedVideo.each((index, obj) => {
            const $videoModule = obj;
            const $videoSlides = $('.video-slides', $videoModule)[0];
            const $prev = $('.slide-prev', $videoModule);
            const $next = $('.slide-next', $videoModule);

            if ($videoSlides && $videoSlides.id) {
                const $videoSlidesInstance = $(`#${$videoSlides.id}`);
                this.VideoUtility = new VideoUtility(this.$videoWrapper, this.videoPlayerStr, this.app, $videoSlidesInstance);
                this.initListeners($videoSlidesInstance, $videoModule);

                // initialize slick
                $videoSlidesInstance.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: $prev,
                    nextArrow: $next,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: false
                });
            }
        });
    }

    initListeners(instance, module) {
        // Start the timer when the slider is initialized
        instance.on('init', () => {
            $('.slider-countdown', module).addClass('countingDown');
        });

        //reset the timer when the slide changes
        instance.on('beforeChange', () => {
            $('.slider-countdown', module).removeClass('countingDown');
            // Pause video players
            this.VideoUtility.pauseAllPlayers(this.VideoUtility.players);
        });

        //restart the timer when the slide changes
        instance.on('afterChange', () => {
            if(!instance.hasClass('paused')) {
                //only start the counter if the slider is not paused
                $('.slider-countdown', module).addClass('countingDown');
            }
        });

    }    
}