import { DefaultSearch }  from '@modules/search';

export default class ClientStoriesSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        // Remove search bar for client stories
        $.hook('client-stories-search-bar')
            .addClass('no-search-field')
            .find('.search-field').remove();

        const $searchResultsTemplate      = $.hook('client-stories-results-item-template').remove();

        this.$clientStorySearchResults    = $.hook('client-stories-search-results');

        this.searchResultsTemplate        = $searchResultsTemplate.html();
        this.searchBoxInputStr            = $.hookStr('search-box-input');
        this.searchResultItemsStr         = $.hookStr('search-results-items');

        this.attributesToFilterBy         = ['industries.lvl0', 'industries.lvl1', 'services.lvl0', 'services.lvl1'];

        // Bail out early if perspectives search does not exist
        if (!this.$clientStorySearchResults.length) {
            return;
        }

        this.setupFilters();

        // Use the date descending index
        this.INDEX_NAME = `${this.INDEX_NAME}_date_desc`;

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        // Limit search to client stories
        this.searchTemplate = 'ClientStory';

        // Custom configuration for client stories search
        this.config = {
            hitsPerPage: 6,
            filters: `templateName:${this.searchTemplate}`,
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Setup the config for each widget
        this.widgets = [
            {
                searchBox: {
                    virtual: true
                }
            }, {
                stats: {
                    container: this.$stats[0],
                    renderFunc: this.renderStats.bind(this)
                }
            }, {
                currentRefinements: {
                    container: this.$selectedFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderCurrentRefinements.bind(this)
                }
            }, {
                clearRefinements: {
                    container: this.$clearFilters[0],
                    includedAttributes: this.attributesToFilterBy,
                    renderFunc: this.renderClearRefinements.bind(this)
                }
            }, {
                // Displaying results
                hits: {
                    container: this.$clientStorySearchResults[0],
                    renderFunc: this.renderHits.bind(this),
                    templates: {
                        item: this.searchResultsTemplate
                    }
                },
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this)
                }
            }
        ];

        this.init();
    }
}
