import ListenerHelpers from './listener-helpers.js';

const listenerHelpers = new ListenerHelpers();

export default function(app) {
    return {
        'resizeIframeHeight': function(message){
            //set the heights of the form iframes on the page
            listenerHelpers.getIframeById(message.frame).css('height', message.data.height+'px');
        },
        'nextStep': function(message) {
            //go to the next step of the form
            this.pardotForm = $.hook('pardot-form');
            var activeStep = this.pardotForm.find('.activeStep');
            //get the id of the current active step
            var activeStepID = $(activeStep).attr('data-form-step');
            //get the id of the next step
            var nextStepID = parseInt(activeStepID) + 1;

            //switch form classes
            $(activeStep).removeClass('activeStep').siblings().addClass('activeStep');

            //find the next form
            const $form = this.pardotForm.find('.form-step[data-form-step="'+nextStepID+'"]');
            var frameURL = $form.attr('data-iframe-url');

            //load the iframe
            setTimeout(function(){
                $form.find('iframe').attr('src', frameURL);
            }, 50);

            //activeStep = this.pardotForm.find('.form-step.activeStep');
            // console.log(activeStep);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'formClickNext',
                formName: activeStep.attr('data-form-name'),
                formType: activeStep.attr('data-form-type')
            });
        },
        'subscribeSubmit': function(message) {
            //hide the headline after the form has been submitted
            $.hook('pardot-form').find('.subscribe-headline').addClass('hideHeadline');
        },
        'formLoaded': $.debounce((message) => {
            listenerHelpers.basicFormEvent('formViewed', message);
            if (typeof app !== 'undefined') {
                app.dispatch(
                    app.modules.PostMessageListener.constructor,
                    'FORM_LOADED', message
                );
            }
        }, 250),
        'formThankYou': $.debounce((message) => {
            //scroll the page back to the top after form submission
            var iframePosition = listenerHelpers.getIframeById(message.frame).offset().top - 200;
            console.log(iframePosition);
            window.scrollTo(0,iframePosition);
            listenerHelpers.basicFormEvent('formSubmitSuccess', message);
        }, 250),
        'formEmailChange': function(message) {
            if (typeof app !== 'undefined') {
                app.dispatch(
                    app.modules.PostMessageListener.constructor,
                    'EMAIL_CHANGE',
                    message
                );
            }
        },
        'formFooterSubmit': function(message) {
            if (typeof app !== 'undefined') {
                app.dispatch(
                    app.modules.PostMessageListener.constructor,
                    'FORM_FOOTER_SUBMIT',
                    message
                );
            }
        }
    };
}