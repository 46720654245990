import { GetYoDigits } from 'foundation-sites/js/foundation.core.utils';

export default class VideoPlayer {
    constructor(opts) {
        // Options are passed as an object
        this.opts = opts || {};

        this.app = this.opts.app || {};
        this.id = null;
        this.type = null;
        this.videoId = null;
        this.videoUrl = null;
        this.player = null;
        this.$videoPlayer = this.opts.$videoPlayer;

        // Bail early if not video player element exist
        if (this.$videoPlayer.length === 0) {
            return;
        }

        this.setPlayer();

        // Load API base on the player type
        this.loadPlayerAPI();

        return this;
    }

    /**
     * Load the API script for Vimeo or Youtube
     */
    loadPlayerAPI() {
        // Bail early if no player placeholder
        if (!this.$videoPlayer.length) {
            return;
        }

        // Load the API onto the page if it doesn't exist yet
        const apiId = `${this.type}-player-api`;
        if ($(`#${apiId}`).length === 0) {
            const tag = document.createElement('script');
            tag.id = apiId;
            tag.src = (this.type === 'vimeo')
                ? 'https://player.vimeo.com/api/player.js'
                : 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Load the player
            // @devNote: Vimeo doesn't have an API_READY call so we load this same
            // function to trigger the else statement that will init an interval
            // until the Vimeo script is loaded
            (this.type === 'vimeo') ? this.loadPlayerAPI() : this.loadPlayer();
        } else {
            // The script has been added to the page but not loaded
            // this is triggered when more than one video is loaded
            // on the same page
            const maxAttempts = 20;
            let attempt = 0;
            const videoApiScriptInterval = setInterval(() => {
                // Clear interval if script is loaded
                if (
                    this.type === 'vimeo' &&
                    (typeof(window.Vimeo) !== 'undefined' && typeof(window.Vimeo.Player) !== 'undefined')
                ) {
                    this.loadPlayer();
                    clearInterval(videoApiScriptInterval);
                } else if (typeof(window.YT) !== 'undefined' && typeof(window.YT.Player) !== 'undefined') {
                    // The API is ready fire this directly
                    this._onYouTubeIframeAPIReady();
                    clearInterval(videoApiScriptInterval);
                }

                // Clear interval if maxAttemps
                if (attempt >= maxAttempts) {
                    clearInterval(videoApiScriptInterval);
                }

                attempt++;
            }, 250);
        }
    }

    loadPlayer() {
        // After the API has been loaded, load the iframe
        if (this.type === 'vimeo') {
            this._loadVimeoEmbed();
        } else {
            window.onYouTubeIframeAPIReady = this._onYouTubeIframeAPIReady.bind(this);
        }
    }

    /**
     * Load the Vimeo JSON code containing the video iframe
     */
    _loadVimeoEmbed() {
        $.get(`https://vimeo.com/api/oembed.json?url=${this.videoUrl}`, (data) => {
            if (data && data.html !== '') {
                const $vimeoIframe = $(data.html);
                $vimeoIframe.attr({
                    'id': this.id,
                    'class': 'video-player'
                });

                // Replace the placeholder with the actual iframe
                this.$videoPlayer.replaceWith($vimeoIframe);
                this.player = new Vimeo.Player($vimeoIframe[0]);

                // Fire player ready callback
                this.player.ready().then(this.opts.onPlayerReady || $.noop);
                // Fire player events
                this.player.on('ended', event => this.app.dispatch(VideoPlayer, 'ENDED', this));
                this.player.on('play', event => this.app.dispatch(VideoPlayer, 'PLAYING', this));
                this.player.on('pause', event => this.app.dispatch(VideoPlayer, 'PAUSED', this));
                this.player.on('error', event => this.app.dispatch(VideoPlayer, 'ERROR', this));
            }
        }).fail(this.opts.onError || $.noop);
    }

    _onYouTubeIframeAPIReady() {
        this.app.dispatch(VideoPlayer, 'YOUTUBE_API_READY');

        // Initialize YouTube video
        this.player = new YT.Player(this.id, {
            videoId: this.videoId,
            height: '390',
            width: '640',
            events: {
                // See https://developers.google.com/youtube/iframe_api_reference#Adding_event_listener for more info on each event
                onReady: this.opts.onPlayerReady || $.noop,
                onStateChange: this.opts.onStateChange || this.onStateChange.bind(this),
                onError: this.opts.onError || $.noop,
            },
            playerVars: {
                enablejsapi: 1,
                origin: window.location.origin
            },
        });
    }

    /**
     * YouTube Events methods
     */
    onPlayerReady() {}

    onStateChange(state) {
        switch(state.data) {
        case YT.PlayerState.ENDED:
            this.app.dispatch(VideoPlayer, 'ENDED', this);
            break;
        case YT.PlayerState.PLAYING:
            this.app.dispatch(VideoPlayer, 'PLAYING', this);
            break;
        case YT.PlayerState.PAUSED:
            this.app.dispatch(VideoPlayer, 'PAUSED', this);
            break;
        }
    }

    onError() {
        // We need design direction when a video fails to load
    }

    /**
     * Add the "id" attr to those elements missing it
     * @return {String}
     */
    setPlayer() {
        let playerId = this.$videoPlayer.attr('id');
        const videoUrl = this.$videoPlayer.data('video-url');
        const videoData = VideoPlayer.getVideoData(videoUrl);

        // If element doesn't have an id attr, fix it
        if (typeof(playerId) === 'undefined' || playerId === '') {
            playerId = GetYoDigits(6, 'video-player');
            this.$videoPlayer.attr('id', playerId);
        }

        this.id = playerId;
        this.type = videoData.type;
        this.videoId = videoData.id;
        this.videoUrl = videoData.videoUrl;
    }

    /**
     * Use a Regular expression to get the video Id from any valid YouTube Url
     * @param  {String} url
     * @return {String}
     */
    static getVideoData(url) {
        let id;
        let type;
        let videoUrl;
        // Quick check to see if it's a Vimeo video, we start with vimeo because
        // they have a simpler url scheme than youtube
        if (url.indexOf('vimeo') > -1) {
            videoUrl = url.replace(/(?:https?:\/\/(?:[\w]+\.)*vimeo\.com(?:[/\w:]*(?:\/videos)?)?\/([0-9]+)[^\s]*)/gi, 'https://vimeo.com/$1').trim();
            type = 'vimeo';
        } else {
            id = url.replace(/(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie|\.googleapis)?\.com\/\S*?[^\w\s-])((?!videoseries)[\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi, '$1').trim();
            type = 'youtube';
        }

        return { id, type, videoUrl };
    }

    /**
     * Player control methods
     */
    play() {
        if (this.type === 'vimeo') {
            this.player.play();
        } else {
            this.player.playVideo();
        }
    }

    pause() {
        if (!this.player) {
            console.warm('Player: \'this.player\' is not defined');
        }

        if (this.type === 'vimeo') {
            if ('pause' in this.player) {
                this.player.pause();
            }
        } else {
            if ('pauseVideo' in this.player) {
                this.player.pauseVideo();
            }
        }
    }
}