import Cookies from 'js-cookie';

/**
 * DevNote: The Pardot side of this form calls for a `onFooterFormSubmit` function.
 * This can be found on the "Completion Actions" when editing the form in the
 * Thank You Code tab. Snippet below
 *
    <script type="text/javascript">
    if (typeof onFooterFormSubmit=== 'function') {
        onFooterFormSubmit();
    }
    </script>
 */
export default class FooterSubscribe {
    constructor(App) {
        this.app = App;

        this.$footerSubscribe             = $.hook('footer-subscribe');
        this.$footerSubscribeFormWrapper  = this.$footerSubscribe.find('.form-wrapper');
        this.$footerSubscribeForm         = this.$footerSubscribeFormWrapper.find('iframe');
        this.$footerSubscribeModal        = $.hook('footer-subscribe-modal');

        this.cookieName                   = 'WM_UTM_QUERY_STRING';
        this.step1Email                   = '';

        if (!this.$footerSubscribeFormWrapper.length) {
            return;
        }

        this.app.on(this.app, 'APP_DOC_READY', this.initListeners.bind(this));
    }

    initListeners() {
        this.buildFooterForm();
        // Listen to pardot form events
        this.app.modules.PostMessageListener.listen();
        this.app.on(
            this.app.modules.PostMessageListener.constructor,
            'FORM_FOOTER_SUBMIT',
            this.onFooterFormSubmit.bind(this)
        );
        this.app.on(
            this.app.modules.PostMessageListener.constructor,
            'EMAIL_CHANGE',
            this.onFormEmailChange.bind(this)
        );
    }

    buildFooterForm() {
        let frameURL = this.$footerSubscribeFormWrapper.attr('data-iframe-url');
        const utmQueryString = this.getUTMAttrs();

        if (utmQueryString) {
            frameURL = frameURL +'&'+ utmQueryString;
        }
        this.$footerSubscribeForm.attr('src', encodeURI(frameURL));
    }

    getUTMAttrs() {
        const utmQuery = window.location.search.substr(1);

        if (utmQuery) {
            Cookies.set(this.cookieName, utmQuery);

            return utmQuery;
        }

        return Cookies.get(this.cookieName) || '';
    }

    onFooterFormSubmit(event, data) {
        this.$footerSubscribeModal.foundation('open');
        this.embedSubscribeForm();
    }

    onFormEmailChange(event, data) {
        if (data.formType && data.formType === 'footerSubscribe' && data.email) {
            this.step1Email = data.email;
        }
    }

    /**
     * Load the iframe when the modal is open
     */
    embedSubscribeForm() {
        console.log('EMAIL', this.step1Email);
        const utmQueryString = this.getUTMAttrs();
        const $form = this.$footerSubscribeModal.find('.form-wrapper');
        const frameURL = $form.attr('data-iframe-url')
            + '&preFilledEmail=' + this.step1Email
            + '&hideEmail=true&' + utmQueryString;

        $form.find('iframe').attr('src', frameURL);
    }
}