export default class ReadTime {
    constructor(App) {
        this.app = App;

        this.$readTime = $.hook('read-time');
        this.$readTimeHolder = $.hook('read-time-holder');
        this.$progress = $.hook('read-progress');
        this.$progressBar = this.$progress.find('.read-progress-bar');
        this.$elements = this.$readTime
            .parents('.main')
            .children()
            .filter(':not(.cmpt__prefooter-cta)');
        this.isProgressBarFixed = false;
        this.fixedClass = 'read-progress--fixed';

        this.showReadTime();
        this.initListeners();
    }

    initListeners() {
        if (!this.$progressBar.length) {
            return;
        }

        // Ensure nav is in the right position on resize
        this.app.$win.on('resize', $.debounce(this.onResize.bind(this), 250));

        this.app.$win.on('load scroll', this.onScroll.bind(this));
    }

    onScroll() {
        const scrollTop = document.documentElement['scrollTop'] || document.body['scrollTop'];
        const scrollBottom = (document.documentElement['scrollHeight'] || document.body['scrollHeight']) - window.innerHeight;
        const scrollPercent = scrollTop / scrollBottom * 100 + '%';

        if (this.app.modules.Header.isHeaderFixed) {
            this.updateProgress(scrollPercent);

            if (!this.isProgressBarFixed) {
                this.lockProgress();
            }

            this.isProgressBarFixed = true;
        } else {
            if (this.isProgressBarFixed) {
                this.unlockProgress();
            }
            this.isProgressBarFixed = false;
        }
    }

    onResize() {
        if (this.app.modules.Header.isHeaderFixed) {
            this.lockProgress();
        }
    }

    updateProgress(scrollPercent) {
        this.$progressBar[0].style.setProperty('width', scrollPercent);
    }

    lockProgress() {
        const { titleBarHeight } = this.app.modules.PersistentNav;

        this.$progress.addClass(this.fixedClass);
        this.$progress.css('top', titleBarHeight);
    }

    unlockProgress() {
        this.$progress.removeClass(this.fixedClass);
        this.$progress.removeAttr('style');
    }

    showReadTime() {
        if (!this.$readTime.length || !this.$readTimeHolder.length || this.excludedPage()) {
            return;
        }

        const wordsPerMinute = 250;
        let readTime = ' | ';
        const readTimeOverride = this.$readTime.data('read-override').toLowerCase() === 'true';

        if (readTimeOverride) {
            readTime += this.$readTime.text();
        } else {
            let dataLayerReadTimeFound = false;
            window.dataLayer.forEach(obj => {
                if (obj.event=='dataLayer-initialized') {
                    if (obj.readTimeMinutes!==undefined && obj.readTimeMinutes!='') {
                        readTime += obj.readTimeMinutes+'-minute read';
                        this.$readTimeHolder.html(readTime);
                        dataLayerReadTimeFound = true;
                    }
                }
            });
            if (!dataLayerReadTimeFound) {
                readTime += Math.round(this.countWords(this.$elements.text()) / wordsPerMinute) + '-minute read';
            }
        }

        this.$readTimeHolder.html(readTime);
    }

    countWords(s) {
        s = s.replace(/(^\s*)|(\s*$)/gi, '') //exclude  start and end white-space
            .replace(/[ ]{2,}/gi, ' ') //2 or more space to 1
            .replace(/\n /, '\n') // exclude newline with a start spacing
            .replace(/^\s*[\r\n]/gm, ''); // Remove carriage return

        return s.split(' ').filter(String).length;
    }

    /**
     * Check if we should exclude the current page
     */
    excludedPage() {
        const segments = location.pathname.split('/').filter((v) => Boolean(v));
        const pagesToExclude = ['video', 'webinar'];

        if (segments[0] === 'perspectives') {
            return pagesToExclude.includes(segments[1]);
        }

        return false;
    }
}