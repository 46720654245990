export default class FullWidthContentCarousel {
    constructor(App) {
        this.app = App;
        this.initListeners();
    }

    contentSlides() {
        $.hook('full-width-content-carousel').each(function(index, obj){

            const $contentModule = obj;
            const $contentSlides = $('.full-width-content-carousel', $contentModule)[0];
            const $prev = $('.slide-circular-prev', $contentModule);
            const $next = $('.slide-circular-next', $contentModule);

            if ($contentSlides && $contentSlides.id) {
                const $contentSlidesInstance = $(`#${$contentSlides.id}`);

                $contentSlidesInstance.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: $prev,
                    nextArrow: $next,
                    autoplay: false,
                    autoplaySpeed: 5000,
                    pauseOnHover: false,
                    centerMode: false,
                    infinite: true,
                    variableWidth: false
                });
            }
        });
    }

    initListeners() {
        this.contentSlides();
    }
}

