import listeners from './listeners.js';

export default class PostMessageListener {

    constructor(App) {
        this.app = App;
        this.listeners = listeners(App);
        this.form = $.hook('pardot-form').find('iframe');
        this.listen();

        //get the subscribe modal
        this.$subscribeModal = $.hook('subscribe-modal');
        //this.$subscribeModal.on('open.zf.reveal', this.subscribeEmbed.bind(this));

        $('[data-open="subscribeModal"]').on('click', this.subscribeEmbed.bind(this));

        this.$gatedModal = $.hook('gated-modal');
        // the button that is clicked to trigger the modal
        this.$trigger = $.hook('modal-trigger');
        this.$ctaFormSwapToggle = $.hook('cta-from-swap-toggle');
        this.$trigger.on('click', this.gatedEmbed.bind(this));
        this.$ctaFormSwapToggle.on('click', this.ctaFormSwap.bind(this));

        // Load first "swap form" if swapping forms
        if (this.$ctaFormSwapToggle.length) {
            this.ctaFormSwap();
        }
    }

    listen() {
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        var eventer = window[eventMethod];
        var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
        // Listen for a message from the iframe.
        eventer(messageEvent, e => {
            //console.log(e);

            if(typeof e.data.message != 'undefined' && typeof this.listeners[e.data.message] != 'undefined'){
                this.listeners[e.data.message](e.data);

            }
        }, false);

    }

    gatedEmbed(e) {
        e.preventDefault();
        //get the trigger
        const $el = $(e.currentTarget);

        //load the iframe when the modal is opened
        var modalID = $el.data('open');
        const $form = this.$gatedModal.filter(`[id="${modalID}"]`).find('.gated-form');
        var frameURL = $form.attr('data-iframe-url');

        $form.find('iframe').attr('src', frameURL);

        if(modalID === 'contactModal' || modalID === 'teamBioModal') {
            //if we are on a contact form, perform some additional actions

            this.memberName = $el.attr('data-member-name');
            if(this.memberName) {
                //if the button clicked is the team member email icon
                this.$gatedModal.find('.contact-modal-headline').text('Contact ' + this.memberName);
            } else {
                //if it's just a contact cta button
                this.contactCTA = $el.text();
                this.$gatedModal.find('.contact-modal-headline').text(this.contactCTA);
            }
            this.formType = $el.attr('data-form-type');
            if(this.formType){
                this.$gatedModal.find('.gated-form').attr('data-form-type', this.formType);
            }
        }

    }

    ctaFormSwap(e) {
        let $form;
        const $forms = $.hook('cta-form-swap').addClass('hide');

        // This function can ba called without an "event" check for that
        if (e) {
            e.preventDefault();
            const $el = $(e.currentTarget);
            const formID = $el.data('open');
            $form = $forms.filter(`[id="${formID}"]`);
        } else {
            $form = $forms.first();
        }

        // Get the forms
        const frameURL = $form.find('.gated-form').attr('data-iframe-url');

        $form.find('.gated-form iframe').attr('src', frameURL);
        $form.removeClass('hide');
    }

    subscribeEmbed(e) {
        e.preventDefault();
        //get the trigger
        const $el = $(e.currentTarget);

        //load the iframe when the modal is opened
        const $form = this.$subscribeModal.find('.form-step[data-form-step="1"]');
        var frameURL = $form.attr('data-iframe-url');

        $form.find('iframe').attr('src', frameURL);

        var formType = $el.attr('data-form-type');

        if(formType){
            this.$subscribeModal.find('.form-step').attr('data-form-type', formType);
        }
    }
}