import AlgoliaClient  from '@core/algolia-client';
import nestedRefinements from './widgets/nested-refinements';
import SearchRouting, { validRefinements } from './search-routing';

export default class DefaultSearch extends AlgoliaClient {
    constructor(App) {
        super({routing: SearchRouting});

        this.app = App;

        // Make sure that the App instance is passed to the Algolia Client
        if (typeof(App) === 'undefined' || !App) {
            return console.error(
                'An instance of the @core/App must be passed to the constructor.'
            );
        }

        this.$searchResults = $('#search-results');
        this.$pagination = $.hook('pagination');
        this.$stats = $.hook('search-results-count');
        this.$filters = $.hook('search-filters');
        this.$filterType = $.hook('search-filter-type');
        this.$filterOptions = $.hook('search-filter-options');
        this.$selectedFilters = $.hook('search-selected-filters-list');
        this.$clearFilters = $.hook('clear-filter');
        this.$viewAll = $.hook('view-all');

        this.monthsMap = {
            january: 1,
            february: 2,
            march: 3,
            april: 4,
            may: 5,
            june: 6,
            july: 7,
            august: 8,
            september: 9,
            october: 10,
            november: 11,
            december: 12
        };

        this.monthsArr = Object.keys(this.monthsMap);

        this.monthsAbbreviations = [
            'Jan.',
            'Feb.',
            'March',
            'April',
            'May',
            'June',
            'July',
            'Aug.',
            'Sept.',
            'Oct.',
            'Nov.',
            'Dec.',
        ];

        this.validUrls = [
            'westmonroe.com',
            'www.westmonroe.com',
            'westmonroepartners.com',
            'www.westmonroepartners.com',
            'wmp.com',
            'www.wmp.com',
            'westmonroe.ext.happycogdev.com',
        ];

        this.debounceId = null;
    }

    /**
     * This method should be called from the parent class to enable filters
     * @return {void}
     */
    setupFilters(opts = {}) {
        const isSearchLanding = opts.isSearchLanding || false;

        // Add listeners for filters only if they exists
        if (this._filtersExists()) {
            this._buildFilterWidgets(isSearchLanding);
            this.initFilterListeners();
        }
    }

    /**
     * Render our custom search box
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderSearchBox(renderOptions, isFirstRender) {
        const { query, refine, clear, widgetParams } = renderOptions;
        const $container = $(widgetParams.container);
        const $input = $container.find('.search-field-input');
        const $submit = $container.find('.search-submit');
        const $clear = $container.find('.search-clear');

        if (isFirstRender) {
            $submit.on('click', event => event.preventDefault());
            $input.on('keyup', event => refine(event.target.value) );
            $clear.on('click', event => {
                event.preventDefault();
                // Clear the input
                $input.val('');

                clear();
            });

            // Add the query to the input if it exits
            $input.val(query);
        }

        if (query !== '') {
            if ($clear.hasClass('hide')) {
                $submit.addClass('hide');
                $clear.removeClass('hide');
            }
        } else {
            if ($submit.hasClass('hide')) {
                $clear.addClass('hide');
                $submit.removeClass('hide');
            }
        }
    }

    /**
     * Render our custom refinement list
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderRefinementList(isSearchLanding, renderOptions, isFirstRender) {
        const { items, createURL, refine, widgetParams } = renderOptions;

        const renderCheckbox = item => `
            <li class="eyebrow search-filter-item">
                <label for="${item.value}"><input type="checkbox" ${item.isRefined ? 'checked' : ''} value="${item.value}" id="${item.value}" name="${widgetParams.attribute}"> ${item.label}
                </label>
            </li>
        `;

        const renderLink = item => `
            <li class="eyebrow search-filter-item">
                <a href="${createURL(item.value)}" class="${item.isRefined ? 'selected' : ''}" data-value="${item.value}">${item.label}</a>
            </li>
        `;

        if (items.length) {
            widgetParams.container.innerHTML = `
                <ul class="search-filter-options-list">
                    ${items.map(isSearchLanding ? renderCheckbox : renderLink).join('')}
                </ul>
            `;

            $(widgetParams.container).off('click').on('click', 'a', (event) => {
                event.preventDefault();

                // Refine only if we're in a search results page
                if (!isSearchLanding) {
                    refine(event.currentTarget.dataset.value);
                }
            });
        } else {
            // No facets available
            widgetParams.container.innerHTML = '<p class="no-facets">No filters available</p>';
        }
    }

    /**
     * Render our custom hierarchical menu
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderHierarchicalMenu(renderOptions, isFirstRender) {
        const { items, refine, widgetParams } = renderOptions;

        if (items.length) {
            widgetParams.container.innerHTML = `
            <ul class="search-filter-options-list">
            ${items.map( item => `
                <li class="eyebrow search-filter-item">
                    <label for="${item.value}"><input type="checkbox" ${item.isRefined ? 'checked' : ''} value="${item.value}" id="${item.value}"> ${item.label}</label>
                    ${item.data ? `
                        <ul class="search-filter-options-sublist">
                            ${item.data.map(subItem => `
                                <li class="eyebrow search-filter-item">
                                    <label for="${subItem.value}"><input type="checkbox" ${subItem.isRefined ? 'checked' : ''} value="${subItem.value}" id="${subItem.value}"> ${subItem.label}</label>
                                </li>
                            `).join('')}
                        </ul>
                    ` : ''}
                </li>
                `).join('')}
            </ul>
            `;

            $(widgetParams.container).find('input').on('change', (event) => {
                refine(event.currentTarget.value);
            });
        }
    }

    /**
     * Render the selected refinements
     * @param  {Object} renderOptions
     * @return {void}
     */
    renderCurrentRefinements(renderOptions) {
        const { items, refine, widgetParams } = renderOptions;
        const $container = $(widgetParams.container);

        // Show/hide filters container
        $container.parent().toggleClass('hide', !items.length);

        const createDataAttribtues = refinement =>
            Object.keys(refinement)
                .map(key => `data-${key}="${refinement[key]}"`)
                .join(' ');

        // Create list of params
        widgetParams.container.innerHTML = items.map(item => `
            ${item.refinements.map( refinement => `
                <a href="#" ${createDataAttribtues(refinement)} class="selected-filter">${refinement.label}</a>
            `).join('')}
        `).join('');

        // Remove filter on click
        $container.find('a').on('click', event => {
            event.preventDefault();

            const item = Object.keys(event.currentTarget.dataset).reduce((acc, key) => {
                return $.extend(acc, {
                    [key]: event.currentTarget.dataset[key]
                });
            }, {});

            refine(item);
        });
    }

    /**
     * Display link to clear refinement options
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderClearRefinements(renderOptions, isFirstRender) {
        const { hasRefinements, refine } = renderOptions;

        if (isFirstRender) {
            this.$clearFilters.on('click', event => {
                event.preventDefault();

                refine();
            });
        }

        // Show or hide depending if refinements exists
        this.$clearFilters.toggleClass('hide', !hasRefinements);
    }

    /**
     * Show results
     * @param  {Array} options.hits
     * @param  {Object} options.widgetParams
     * @return {void}
     */
    renderHits({hits, widgetParams}) {
        const $container = $(widgetParams.container);
        // Clear the container
        $container.html('');

        // Show no results message
        if (!hits.length) {
            $container.html('<strong>Sorry, there were no results found. Please try searching another term.</strong>');
        }

        hits = this._transformHits(hits);

        $.each(hits, (i, item) => {
            $container.append(this.render(this.templates.hits, 'item', item));
        });
    }

    /**
     * Renders a generic pagination, can be used in all search modules
     * @param  {Object}  renderOptions
     * @param  {Boolean} isFirstRender
     * @return {void}
     */
    renderPagination(renderOptions, isFirstRender) {
        const { pages, currentRefinement, refine, isFirstPage, isLastPage } = renderOptions;

        // Listen to pages click
        if (isFirstRender) {
            this.$pagination.on('click', 'a', (event) => {
                event.preventDefault();
                const offset = this.$searchResults.offset().top - this.app.modules.Header.$header.outerHeight();

                // Scroll to top of page and then refine results
                $('html, body').animate({
                    scrollTop: offset,
                }, 'fast', () => {
                    refine(event.currentTarget.dataset.value);
                });
            });
        }

        // Only add pagination if more than 1 page
        if (pages.length <= 1) {
            return this.$pagination.html('');
        }

        const previousPage = (!isFirstPage ) ? `<a href="#" class="page" data-value="${currentRefinement - 1}">&lsaquo;</a>` : '';
        const nextPage = (!isLastPage ) ? `<a href="#" class="page" data-value="${currentRefinement + 1}">&rsaquo;</a>` : '';

        this.$pagination.html(`
            ${previousPage}
            ${pages.map(page => `<a href="#" class="page ${currentRefinement === page ? 'currentPage' : ''}" data-value="${page}">${page + 1}</a>`).join('')}
            ${nextPage}
        `);
    }

    /**
     * Render hits stats
     * @param  {Int} options.hitsPerPage
     * @param  {Int} options.nbHits
     * @param  {Int} options.page
     * @return {Void}
     */
    renderStats({ hitsPerPage, nbHits, page }) {
        let from = 1;
        let to = hitsPerPage < nbHits ? hitsPerPage : nbHits;

        // Calculate what results we are displaying
        if (page > 0) {
            from = (hitsPerPage * page) + 1;
            to = from + hitsPerPage - 1;
        }

        this.$stats.html(`<span class="search-count">Showing ${from} - ${to} of ${nbHits} results</span>`);
    }

    /**
     * Query hook used to debounce the search client
     * @param  {String} query
     * @param  {Func} refine
     * @return {void}
     */
    onQueryHook(query, refine) {
        clearTimeout(this.debounceId);
        this.debounceId = setTimeout(() => refine(query), 250);
    }

    updateFormAction() {
        const { pathname } = window.location;
        const resultsPath = pathname.substr(-1) === '/'
            ? 'results'
            : '/results';

        this.$searchBarForm.attr('action', pathname + resultsPath);
    }

    getFilterAttributes() {
        const attributesToFilterBy = [];

        this.$filterOptions.each((i, el) => {
            const attr = el.dataset.attribute;

            if (attr) {
                attributesToFilterBy.push(attr);
            }
        });

        return attributesToFilterBy;
    }

    /**
     * Scroll to the search bar if a valid query is present
     * @param {jQuery} $el
     */
    scrollToSearch($el) {
        const query = window.location.search;
        // Bail early if no query string
        if (!query || !$el.length) {
            return;
        }

        let hasValidQuery = false;
        const queryParams = query.substr(1).split('&').map(param => param.split('=')[0]);
        const validQueryParams = [].concat(validRefinements, ['query']);

        // If one of the query matches the valid refinements
        for (let i = 0; i < queryParams.length; i++) {
            const param = queryParams[i];

            if (validQueryParams.indexOf(param) != -1 && !hasValidQuery) {
                hasValidQuery = true;
                break;
            }
        }

        // Scroll to the search bar if this is a valid query
        $('html, body').animate({
            // 89 is the header height! -1 offset
            scrollTop: $el.offset().top - (89 - 1)
        });
    }

    /**
     * -------------------------------------------------------------
     * Private Methods
     * -------------------------------------------------------------
     */

    _buildFilterWidgets(isSearchLanding) {
        this.$filterOptions.each((i, filterOptions) => {
            const { attribute, refinementType } = filterOptions.dataset;

            // Skip if attribute don't exists
            if (!attribute) {
                return true;
            }

            // Display a hierarchical menu
            if (refinementType === 'hierarchical') {
                // Update this to use a custom widget so we can show all nested filters at once
                // and select multiple filter at once, should also show on current refinements widget
                this.widgets = [{
                    nestedRefinements: {
                        // Must be set so the algolia client doesn't ignore it
                        custom: true,
                        attributes: [
                            attribute + '.lvl0',
                            attribute + '.lvl1'
                        ],
                        limit: 20,
                        container: filterOptions,
                        sortBy: ['name:asc'],
                        widget: nestedRefinements,
                        isSearchLanding: isSearchLanding
                    }
                }];
            } else {
                // Add refinementList widget otherwise
                this.widgets = [{
                    refinementList: {
                        attribute,
                        limit: 40,
                        container: filterOptions,
                        sortBy: this._sortBy.bind(this),
                        searchableEscapeFacetValues: false,
                        renderFunc: this.renderRefinementList.bind(this, isSearchLanding)
                    }
                }];
            }
        });
    }

    initFilterListeners() {
        this.app.$win.on('load resize', $.debounce(this._onLoadResize.bind(this), 500));

        this.$filterType.off('click').on('click', (event) => {
            event.preventDefault();
            const $el = $(event.currentTarget);
            const $currentFilters = $el.next();
            const attr = $el.data('attribute');

            this.$filterType.filter(`:not([data-attribute=${attr}])`).removeClass('open');
            $el.toggleClass('open');

            // Hide all active options
            this.$filterOptions.filter(`:not([data-attribute=${attr}])`).slideUp('fast');

            // Show selected options
            $currentFilters.slideToggle('fast');
        });

        // Close filter options on outside click
        $('body').on('click', event => {
            this.$filterType.removeClass('open');
            this.$filterOptions.slideUp();
        });
        this.$filters.on('click', (event) => event.stopPropagation());
    }

    _onLoadResize() {
        this._adjustFilterOptions();
    }

    /**
     * @private
     * Checks to see if the filters exist on the page
     * @return {Boolean}
     */
    _filtersExists() {
        return (this.$filters.length && this.$filterType.length && this.$filterOptions.length);
    }

    /**
     * @private
     * Adjusts the position of each filter options if they're outside the viewport horizontaly
     * @return {void}
     */
    _adjustFilterOptions() {
        // Bail early if filters don't exists
        if (!this._filtersExists()) {
            return;
        }

        // Clear out existing styles first
        this.$filterOptions.removeAttr('style');

        this.$filterOptions.each((i, options) => {
            const $options = $(options);
            const elDimentions = this._getElementDimentions($options);

            if (this._elementOutsideViewport($options, elDimentions)) {
                this._adjustFilterOptionsPosition($options, elDimentions);
            }
        });
    }

    /**
     * @private
     * Get the width and offset of an element
     * @param  {jQuery} $el
     * @return {Object}
     */
    _getElementDimentions($el) {
        // Show the element but make it "invisible" to get the right offset values
        if (!$el.is(':visible')) {
            $el.css({
                display: 'block',
                visibility: 'hidden',
                opacity: 0 // for good measure
            });
        }

        // Get the element's position
        const elOffset = Math.round($el.offset().left);

        // Clear the styles applied earlier
        $el.removeAttr('style');

        return {
            width: Math.round($el.outerWidth()),
            left: elOffset
        };
    }

    /**
     * @private
     * Checks to see if the given element is outside the viewport by calculating
     * the width + offset against the viewport
     * @param  {Element} el
     * @return {Boolean}
     */
    _elementOutsideViewport($el, dimentions) {
        const { width, left } = dimentions;
        const viewportWidth = this.app.$win.outerWidth();
        // How far out we want to be offset into the viewport
        const threshold = 16;

        return ((width + left) > (viewportWidth - threshold));
    }

    /**
     * @private
     * Updates the left position of the filters to keep it withing the viewport
     * @param  {jQuery} $options
     * @param  {Int} options.left
     * @param  {Int} options.width
     * @return {void}
     */
    _adjustFilterOptionsPosition($options, { left, width }) {
        // Double the threshold to account for the scrollbar
        const threshold = 16 * 2;
        const viewportWidth = this.app.$win.outerWidth();
        // Find out how much off-screen the options are
        const offscreen = (width + left) - viewportWidth;
        const newLeft = offscreen + threshold;

        // Apply the new left offset
        $options.css({
            left: -newLeft
        });
    }

    /**
     * @private
     * Normalize items so they all have the same values
     * @param  {Array}
     * @return {Array}
     */
    _transformHits(items) {
        if (!items.length) {
            return items;
        }

        // Include specific date on these formats
        const includeDateOn = ['Event', 'InBrief', 'In Brief', 'In the Media', 'News', 'Podcast', 'Press Release', 'Webinar'];
        const excludeDateOn = ['ClientStory'];

        return items.map(item => {
            const { templateName } = item;

            // Transform date into human readable date
            if ('date' in item && item.date && (excludeDateOn.indexOf(item.templateName) === -1)) {
                // Build new date
                const d = new Date(0);
                const dateFormatted = [];

                // Set epoch date
                d.setUTCSeconds(parseInt(item.date));
                // 2020.08.21 mduckworth - get and consider the current tz offset so the date is correct
                d.setSeconds(d.getSeconds()+(d.getTimezoneOffset()*60));

                // get month index after timestamp is set
                const monthIndex = d.getMonth();

                // Include specific date on these formats
                if ('formatType' in item && includeDateOn.indexOf(item.formatType) > -1) {
                    // Push abbreviated month and date

                    dateFormatted.push(this.monthsAbbreviations[monthIndex]);
                    dateFormatted.push(d.getDate() + ',');
                } else {
                    // Push full month
                    dateFormatted.push(this.toCap(this.monthsArr[monthIndex]));
                }

                // Push year
                dateFormatted.push(d.getFullYear());

                //If it is an Archive do not show the date
                if(item.isArchive == true ) {
                    item.dateFormatted = 'Archive';
                } else {
                    // Convert date to string
                    item.dateFormatted = dateFormatted.join(' ');
                }
            }

            // Is this item an offsite record?
            if ('url' in item && item.url && this._isUrl(item.url)) {
                item.isOffsite = this._isOffsite(item);
            }

            // Team records
            if (templateName === 'TeamMember') {
                item.title = item.fullName;
            }

            // Update description
            item.description = ('summaryDescription' in item)
                ? item.summaryDescription
                : ('description' in item)
                    ? item.description
                    : '';

            // Normalize eyebrows
            let eyebrow = '';
            /* eslint-disable */
            switch (templateName) {
                case 'Industry':
                    eyebrow = 'Industry';
                    break;
                case 'Service':
                    eyebrow = 'Service';
                    break;
                case 'Offering':
                    eyebrow = 'Offering';
                    break;
                case 'ClientStory':
                    eyebrow = 'Client Story';
                    break;
                case 'Office':
                    eyebrow = 'Office';
                    break;
                case 'CareerResources':
                    eyebrow = 'Careers';
                    break;
                case 'TeamMember':
                    eyebrow = 'Our Team';
                    break;
                case 'Blog':
                    eyebrow = 'Blog';
                    break;
                // There are different eyebrows in generic so let's check the url
                // we use double / to make sure we checking for the right value
                case 'Generic':
                    if ('url' in item && item.url) {
                        if (item.url.indexOf('/values/') > -1) {
                            eyebrow = 'What We Value';
                        } else if (item.url.indexOf('/careers/') > -1) {
                            eyebrow = 'Careers';
                        } else if (item.url.indexOf('/partners/') > -1) {
                            eyebrow = 'Partner';
                        }
                    }
                    break;
                }
            /* eslint-enable */
            item.eyebrow = eyebrow;

            // Replace escaped characters
            if ('formatType' in item) {
                item.eyebrow = item.formatType.replace('&amp;', '&');
            }

            if (item.topicsBlog!==undefined && item.topicsBlog.length>0) {
                item.eyebrow = item.topicsBlog[0].replace('&amp;', '&');
            }

            // This supports CDN.  CDN url is supplied to the JS via the GlobalConfig and if it is set, any existing domains are stripped
            // and replaced with the CDN url.
            var removeList = ['https://www.westmonroepartners.com', 'www.westmonroepartners.com', 'https://westmonroe.ext.happycogdev.com', 'www.westmonroe.com', 'https://www.westmonroe.com'];
            if ('image' in item) {
                if (window.WestMonroe.GlobalConfig.CdnUrlPrefix!==undefined && window.WestMonroe.GlobalConfig.CdnUrlPrefix!='') {
                    removeList.forEach(function(prefix) {
                        if (item.image.startsWith(prefix)) {
                            var newUrl = item.image.replace(prefix, '');
                            if (newUrl!=item.image) {
                                item.image = window.WestMonroe.GlobalConfig.CdnUrlPrefix + newUrl;
                            }
                        }
                    });
                }
            }

            if ('cardLogo' in item) {
                if (window.WestMonroe.GlobalConfig.CdnUrlPrefix!==undefined && window.WestMonroe.GlobalConfig.CdnUrlPrefix!='') {
                    removeList.forEach(function(prefix) {
                        if (item.cardLogo.startsWith(prefix)) {
                            var cardLogoNewUrl = item.cardLogo.replace(prefix, '');
                            if (cardLogoNewUrl!=item.cardLogo) {
                                item.cardLogo = window.WestMonroe.GlobalConfig.CdnUrlPrefix + cardLogoNewUrl;
                            }
                        }
                    });
                }
            }

            return item;
        });
    }

    /**
     * Checks if this is an external link
     * @param {Object} item
     */
    _isOffsite(item) {
        const url = new URL(item.url);

        return (
            item.templateName === 'OffsiteNewsArticle' ||
            (url.hostname !== window.location.hostname) ||
            (!this.validUrls.includes(url.hostname))
        ) || false;
    }

    /**
     * Checks whether the param is a valid URL
     * @param url string
     * @returns {boolean}
     */
    _isUrl(url) {
        return /^(ftp|http|https):\/\/[^ "]+$/.test(url.trim());
    }

    _sortBy(a, b) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        const intA = parseInt(nameA);
        const intB = parseInt(nameB);

        // Are we sorting months
        if (this.monthsArr.includes(nameA) && this.monthsArr.includes(nameB)) {
            return this.monthsMap[nameA] - this.monthsMap[nameB];
        }

        // Are we sorting an int/date? show higher/newer first
        if (!isNaN(intA) && !isNaN(intB)) {
            return intB - intA;
        }

        // Default sort [name:asc]
        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }
}