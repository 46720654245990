export default class FeatureServiceBlock {
    constructor(App) {
        this.app = App;

        this.$serviceBlock = $.hook('feature-service-block');
        this.$serviceBlockImg = $.hook('feature-service-block-img');
        this.$serviceBlockHeading = $.hook('feature-service-block-heading');
        this.$serviceBlockItems = $.hook('feature-service-block-items');
        this.$serviceBlockCard = $.hook('feature-service-block-card');
        this.$mobileHeadingContainer = $.hook('heading-mobile');
        this.$desktopHeadingContainer = $.hook('heading-desktop');

        // Save feature block img url
        this.serviceBlockImgUrl = this.$serviceBlockImg.css('background-image');

        this.mobileAdjusted = false;

        // Bail early if module don't exist
        if (!this.$serviceBlock.length) {
            return;
        }

        this.initListeners();
    }

    initListeners() {
        this.app.$win.on('load resize', this.adjustForMobile.bind(this));
        this.$serviceBlockItems.on('click', 'a', this.onServiceBlockClick.bind(this));
    }

    /**
     * Adjust module heading position and top attribute for background image
     */
    adjustForMobile() {
        // Adjust for mobile
        if (this.app.$win.width() < this.app.breakpoint.large) {
            if (!this.mobileAdjusted) {
                // Move the heading first
                this.$serviceBlockHeading.remove().appendTo(this.$mobileHeadingContainer);

                // Then get heading height
                const headingHeight = parseInt(this.$serviceBlockHeading.outerHeight(true));
                const serviceBlockImgTop = parseInt(this.$serviceBlockImg.css('top'));

                // Apply new value to block img
                this.$serviceBlockImg.css('top', serviceBlockImgTop + headingHeight);

                this.mobileAdjusted = true;
            }
        } else {
            if (this.mobileAdjusted) {
                // Move the heading first
                this.$serviceBlockHeading.remove().appendTo(this.$desktopHeadingContainer);

                // Update block img styles
                this.$serviceBlockImg
                    .removeAttr('style')
                    .css('background-image', this.serviceBlockImgUrl);

                this.mobileAdjusted = false;
            }
        }
    }

    onServiceBlockClick(e) {
        e.preventDefault();

        const $el = $(e.currentTarget);
        const target = $el.attr('data-item');
        const $allItems = this.$serviceBlockItems.find('li');
        const $allTargets = this.$serviceBlockCard.find('li');

        // Remove active class from all items/targets
        $allItems.removeClass('selected');
        $allTargets.removeClass('is-active');

        // Display selected item
        $el.parent().addClass('selected');
        this.$serviceBlockCard.find(`[data-item-target="${target}"]`).addClass('is-active');
    }
}