import { DefaultSearch }  from '@modules/search';

export default class PerspectivesSearch extends DefaultSearch {
    constructor(App) {
        super(App);

        const $searchResultsTemplate      = $.hook('perspectives-results-item-template').remove();

        this.$perspectiveSearchBar        = $.hook('perspectives-search-bar');
        this.$perspectiveSearchResults    = $.hook('perspectives-search-results');
        this.$searchBarForm               = $.hook('search-bar');

        this.isSearchLanding              = Boolean(this.$perspectiveSearchBar.data('search-landing'));

        this.searchResultsTemplate        = $searchResultsTemplate.html();
        this.searchBoxInputStr            = $.hookStr('search-box-input');
        this.searchResultItemsStr         = $.hookStr('search-results-items');

        this.attributesToFilterBy         = ['industries.lvl0', 'industries.lvl1', 'topicsCorporate', 'formatType'];

        // Bail out early if perspectives search does not exist
        if (!this.$perspectiveSearchBar.length && !this.$perspectiveSearchResults.length) {
            return;
        }

        // This is a search landing page, let's setup the form and apply buttons
        if (this.isSearchLanding) {
            this.updateFormAction();
            this.updateFormSubmitButton();
        }

        this.setupFilters({
            isSearchLanding: this.isSearchLanding
        });

        // JS templates
        this.templates = {
            hits: {
                item: this.searchResultsTemplate
            }
        };

        // Use the date descending index
        this.INDEX_NAME = `${this.INDEX_NAME}_date_desc`;

        // Custom configuration for perspectives search
        this.config = {
            hitsPerPage: 6,
            filters: 'templateName:Perspective',
            restrictSearchableAttributes: [
                'title',
                'searchKeywords',
                'summaryTitle',
                'summaryDescription',
                'heroTitle',
                'heroDescription',
                'metaTitle',
                'metaDescription',
            ]
        };

        // Default widgets config that should appear on search landing and search result pages
        // Since results are still being retrieved, these may need to be virtual widgets
        this.widgets = [
            {
                searchBox: {
                    container: this.$perspectiveSearchBar.find('#search-box')[0],
                    renderFunc: this.renderSearchBox.bind(this),
                    virtual: this.isSearchLanding
                }
            }, {
                pagination: {
                    container: this.$pagination[0],
                    renderFunc: this.renderPagination.bind(this),
                    virtual: this.isSearchLanding
                }
            }
        ];

        // These widgets config should only show on the search results page
        if (!this.isSearchLanding) {
            this.widgets = [
                {
                    stats: {
                        container: this.$stats[0],
                        renderFunc: this.renderStats.bind(this)
                    }
                }, {
                    currentRefinements: {
                        container: this.$selectedFilters[0],
                        includedAttributes: this.attributesToFilterBy,
                        renderFunc: this.renderCurrentRefinements.bind(this)
                    }
                }, {
                    clearRefinements: {
                        container: this.$clearFilters[0],
                        includedAttributes: this.attributesToFilterBy,
                        renderFunc: this.renderClearRefinements.bind(this)
                    }
                }, {
                    // Displaying results
                    hits: {
                        container: this.$perspectiveSearchResults[0],
                        renderFunc: this.renderHits.bind(this),
                        templates: {
                            item: this.searchResultsTemplate
                        }
                    },
                }
            ];
        }

        this.init();

        this.afterSearchInit();
    }

    updateFormSubmitButton() {
        let inputFilled = false;
        let filtersSelected = false;

        // Show view all submit button
        this.$viewAll.removeClass('hide');

        // Check if we need to update the text
        this.$perspectiveSearchBar.on('change keyup', 'input', event => {
            const input = event.currentTarget;
            const { type } = input;
            // Check for text and checkbox fields
            if (type === 'text') {
                inputFilled = (input.value !== '');
            } else {
                filtersSelected = Boolean(this.$perspectiveSearchBar.find('input').filter(':checked').length);
            }

            // Update submit button text if search bar filled or filters selected|
            const submitText = (inputFilled || filtersSelected) ? 'Apply' : 'View All';
            this.$viewAll.text(submitText);
        });
    }

    afterSearchInit() {
        this.$perspectiveSearchBar.addClass('search-active');

        // Scroll to search if query
        this.scrollToSearch(this.$perspectiveSearchBar);
    }
}
